import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { OfferService } from '../shared/offer.service';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { ActivatedRoute, Router } from '@angular/router';
import { Option } from 'src/app/shared/select/select.component';
import { EventOfferStatusEnum } from 'src/app/shared/enums/EventOfferStatusEnum';
import { ToastrService } from 'ngx-toastr';
import { Printer } from 'src/app/shared/models/Printer';
import * as moment from 'moment';
import { OriginPlaceEnum } from 'src/app/shared/enums/OriginPlaceEnum';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.css']
})
export class OfferDetailsComponent implements OnInit {

  public eventOffer: EventOffer = new EventOffer();
  public existingEventOffers: EventOffer[] = [];
  public cableObject;
  public offerCompanyPrinters: Printer[];
  public eventOfferStatus: Option[] = [
    { value: EventOfferStatusEnum.Rejected, label: 'Rejected' },
    { value: EventOfferStatusEnum.Pending, label: 'Pending' },
    { value: EventOfferStatusEnum.Accepted, label: 'Accepted' },
    { value: EventOfferStatusEnum.Canceled, label: 'Canceled' },
    { value: EventOfferStatusEnum.NewOfferRequested, label: 'New Offer Requested' },
  ];

  public choosenStatus: number;
  public companyId: string;
  public eventOfferStatusEnum = EventOfferStatusEnum;
  public pickUpDate = '';
  public returnDate = '';
  public originPlaceEnum = OriginPlaceEnum;

  public sendConvertionEmail = false;
  public additionalCostArray: AdditionalCost[];

  @ViewChild('loadingModal', { static: true })
  public loadingModal;


  constructor(private _offerService: OfferService, private _route: ActivatedRoute, private titleService: Title,
    private _toastr: ToastrService, private _router: Router) { }

  ngOnInit() {
    this._route.params.subscribe(paramas => {
      if (paramas['offerId']) {
        this._offerService.getEventOfferById(paramas['offerId']).subscribe(result => {
          console.log(result);
          this.eventOffer = result;
          this.titleService.setTitle("[" + this.eventOffer.id + "] Event Offer - FAdminFrontend");
          this.cableObject = JSON.parse(this.eventOffer.hardwareCable);
          this.offerCompanyPrinters = this.eventOffer.company.printers;
          this.choosenStatus = this.eventOffer.status;
          this.pickUpDate = moment(this.eventOffer.pickUpDate).format('DD-MM-YYYY');
          this.returnDate = moment(this.eventOffer.returnDate).format('DD-MM-YYYY');
          this.cableObject = JSON.parse(this.eventOffer.hardwareCable);
          this._offerService.getEventOffersByLongId(this.eventOffer.offerId).subscribe(eventOffers => {
            this.existingEventOffers = eventOffers;
            const index = this.existingEventOffers.findIndex(offer => offer.id.toString() === paramas['offerId']);
            this.existingEventOffers.splice(index, 1);
          });
        }, error => {
          console.error('Redirect');
          this._router.navigate(['/offers']);
        });
        this._offerService.getAdditionalCosts().subscribe(result1 => {
          console.log('result1:', result1);
          this._offerService.getAdditionalCostsAssigned(paramas['offerId']).subscribe(result2 => {
            console.log('result2:', result2);
            result2.forEach(rec2 => {
              result1.filter(item => item.id === rec2.id)[0].quantityRequested = rec2.quantity;
            });
          });
          this.additionalCostArray = result1;
        });
      } else {
        console.error('Redirect');
        this._router.navigate(['/offers']);
      }
    });
  }

  downloadEventOfferPdf(offerId: string, versionLetter: string) {
    this._offerService.downloadEventOfferPdf(offerId, versionLetter).subscribe(result => {
      saveAs(result, offerId + '_' + versionLetter + '.pdf');
    });
  }

  changeEventOfferStatus() {
    if (this.choosenStatus.toString() !== EventOfferStatusEnum.Rejected.toString() &&
      this.choosenStatus.toString() !== EventOfferStatusEnum.Canceled.toString()) {
      this.eventOffer.negativeNotes = '';
    }
    this._offerService.changeEventOfferStatus(this.eventOffer.id.toString(), this.choosenStatus.toString(),
      this.eventOffer.negativeNotes).subscribe(result => {
        this._toastr.success('Event status updated!');
        this.eventOffer.status = this.choosenStatus;
        if (this.choosenStatus.toString() === EventOfferStatusEnum.NewOfferRequested.toString()) {
          this._router.navigate(['/offers/create'], { queryParams: { id: this.eventOffer.id } });
        }
      });
  }

  changePickupReturnDate() {
    const pickUpDate = moment(this.pickUpDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const returnDate = moment(this.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    if (pickUpDate < returnDate) {
      this._offerService.updateEventOfferPickupReturnDate(this.eventOffer.id.toString(), pickUpDate, returnDate).subscribe(result => {
        this._toastr.success('Dates successfully modified');
        this.eventOffer.pickUpDate = pickUpDate;
        this.eventOffer.returnDate = returnDate;
      });
    } else {
      this._toastr.error('Pickup date cannot be grater than return date!');
    }
  }

  convertOfferToEventButtonClick() {
    console.log(this.sendConvertionEmail);
    this.loadingModal.openDialog();
    this._offerService.convertEventOfferToEvent(this.eventOffer.id.toString(), this.sendConvertionEmail).subscribe(result => {
      this._router.navigate(['/events/details/' + result.event.id]);
      this.loadingModal.closeDialog();
    }, error => {
      console.error(error);
      this.loadingModal.closeDialog();
    });
  }

  sendEventConfermationEmail() {
    if (this.sendConvertionEmail) {
      this._offerService.sendEventConfermationEmail(this.eventOffer.id.toString()).subscribe(result => {
        this.sendConvertionEmail = false;
        this._toastr.success('Email sended');
      });
    } else {
      this._toastr.error('Check the "Confirm" checkbox');
    }
  }

  updateEventOfferNotes(newNotes) {
    this._offerService.updateEventOfferNotes(this.eventOffer.id.toString(), newNotes).subscribe(result => {
      this.eventOffer.notes = newNotes;
      this._toastr.success('Notes updated');
    });
  }
}


