import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Event } from 'src/app/shared/models/Event';
import { RequestedDevice } from 'src/app/shared/models/RequestedDevice';
import { RequestedPrinterType } from 'src/app/shared/models/RequestedPrinterType';
import { RequestedRouterType } from 'src/app/shared/models/RequestedRouterType';
import { RequestedCable } from 'src/app/shared/models/RequestedCable';
import { RequestedSoftwareType } from 'src/app/shared/models/RequestedSoftwareType';
import { RequestedUps } from 'src/app/shared/models/RequestedUps';
import { EventsService } from '../events.service';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';

export class CableSizes {
  one = 0;
  five = 0;
  ten = 0;
  fifteen = 0;
  twenty = 0;
  thirty = 0;
  fifty = 0;
}

@Component({
  selector: 'app-requested-hardware-software-table',
  templateUrl: './requested-hardware-software-table.component.html',
  styleUrls: ['./requested-hardware-software-table.component.css']
})

export class RequestedHardwareSoftwareTableComponent implements OnInit, OnChanges {

  public phonesAmount = 0;
  public tabletAmount = 0;
  public printersAmount = 0;
  public fiscalPrintersAmount = 0;
  public routersAmount = 0;
  public waiterAmount = 0;
  public mainDeviceAmount = 0;
  public cashRegisterAmount = 0;
  public printerAmount = 0;
  public fiscalPrinterAmount = 0;
  public upsAmount = 0;
  public cablesAmount: CableSizes = new CableSizes();
  public additionalCostArray: AdditionalCost[] = [];

  @Input()
  event: Event;

  constructor(private _eventsService: EventsService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    this.phonesAmount = 0;
    this.tabletAmount = 0;
    this.printersAmount = 0;
    this.fiscalPrintersAmount = 0;
    this.routersAmount = 0;
    this.waiterAmount = 0;
    this.mainDeviceAmount = 0;
    this.cashRegisterAmount = 0;
    this.printerAmount = 0;
    this.fiscalPrinterAmount = 0;
    this.upsAmount = 0;
    this.cablesAmount = new CableSizes();
    const event = Event.fromDto(changes.event.currentValue);
    console.log('requestedDevices', event.requestedDevices);
    this.calculateDevicesAmount(event.requestedDevices);
    this.calculatePrintersAmount(event.requestedPrinterTypes);
    this.calculateFiscalPrintersAmount(event.requestedPrinterTypes)
    this.calculateRoutersAmount(event.requestedRouterTypes);
    this.calculateUpsAmount(event.requestedUps);
    this.calculateCableAmount(event.requestedCable);
    this.calculateSoftwareAmount(event.requestedSoftwareTypes);

    this._eventsService.getAdditionalCosts().subscribe(result1 => {
      console.log('result1:', result1);
      if (this.event.id) {
        this._eventsService.getAdditionalCostsAssigned(this.event.id.toString()).subscribe(result2 => {
          console.log('result2:', result2);
          result2.forEach(rec2 => {
            result1.filter(item => item.id === rec2.id)[0].quantityRequested = rec2.quantity;
          });
        });
      }
      this.additionalCostArray = result1;
    });

  }

  calculateDevicesAmount(requestedDevices: RequestedDevice[]) {
    if (!requestedDevices) {
      return;
    }
    requestedDevices.forEach(device => {
      if (device.deviceTypes.name === 'Phone') {
        this.phonesAmount += device.quantity;
      } else {
        this.tabletAmount += device.quantity;
      }
    });
  }

  calculatePrintersAmount(requestedPrinters: RequestedPrinterType[]) {
    if (!requestedPrinters) {
      return;
    }
    requestedPrinters.forEach(printer => {
      if (!printer.printerType.isFiscalPrinter) {
        this.printersAmount += printer.quantity;
      }
    });
  }

  calculateFiscalPrintersAmount(requestedPrinters: RequestedPrinterType[]) {
    if (!requestedPrinters) {
      return;
    }
    requestedPrinters.forEach(fiscalPrinter => {
      if (fiscalPrinter.printerType.isFiscalPrinter) {
        this.fiscalPrintersAmount += fiscalPrinter.quantity;
      }
    });
  }

  calculateRoutersAmount(requestedRouters: RequestedRouterType[]) {
    if (!requestedRouters) {
      return;
    }
    requestedRouters.forEach(router => {
      this.routersAmount += router.quantity;
    });
  }

  calculateUpsAmount(requestedUps: RequestedUps[]) {
    if (!requestedUps) {
      return;
    }
    requestedUps.forEach(ups => {
      this.upsAmount += ups.quantity;
    });
  }

  calculateCableAmount(requestedCable: RequestedCable[]) {
    requestedCable.forEach(cable => {
      switch (cable.cables.cableType.length) {
        case 5:
          this.cablesAmount.five += cable.quantity;
          break;
        case 10:
          this.cablesAmount.ten += cable.quantity;
          break;
        case 15:
          this.cablesAmount.fifteen += cable.quantity;
          break;
        case 20:
          this.cablesAmount.twenty += cable.quantity;
          break;
        case 30:
          this.cablesAmount.thirty += cable.quantity;
          break;
        case 50:
          this.cablesAmount.fifty += cable.quantity;
          break;
        default:
          this.cablesAmount.one += cable.quantity;
          break;
      }
    });
  }

  calculateSoftwareAmount(requestedSoftwareTypes: RequestedSoftwareType[]) {
    for (const request of requestedSoftwareTypes) {
      switch (request.softwareType.id) {
        case 1:
          this.waiterAmount += request.quantity;
          break;
        case 2:
          this.cashRegisterAmount += request.quantity;
          break;
        case 3:
          this.mainDeviceAmount += request.quantity;
          break;
        case 4:
          this.printerAmount += request.quantity;
          break;
        case 5:
          this.fiscalPrinterAmount += request.quantity;
          break;
        default:
          break;
      }
    }
  }

}
