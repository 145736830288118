import { Injectable } from '@angular/core';
import { URL_BASE } from '../../core/constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '../../shared/models/Client';
import { Company } from '../../shared/models/Company';
import { HttpUtils } from 'src/app/shared/http-utils';
import { Country } from '../../shared/models/Country';
import { SearchObjectResult } from '../../shared/models/SearchObject';
import { ClientSearchCriteria } from './client-search-criteria.model';
import { SearchProduct } from 'src/app/shared/models/SearchProduct';
import * as moment from 'moment';
import { Survey } from 'src/app/shared/models/Survey';
import { SurveyAnswer } from 'src/app/shared/models/SurveyAnswer';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private _http: HttpClient) { }

  /**
   * get all the clients
   * ERROR 600 -> No clients in the database
   */
  public getAllClients(): Observable<Client[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/client/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Client.fromDto(res)))
    );
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public search(page: number, size: number, criteria: ClientSearchCriteria): Observable<SearchObjectResult<Client>> {

    const criteriaCopy = { ...criteria };
    if (criteriaCopy.registrationDate) {
      criteriaCopy.registrationDate = moment(criteriaCopy.registrationDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(URL_BASE + '/admin/client/search', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Client.fromDto);
      })
    );
  }

  public getAllCompanies(): Observable<Company[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/company/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Company.fromDto(res)))
    );
  }

  /**
   * get all the countries from the database
   * ERROR 600 -> No countries in the database
   */
  public getAllCountries(): Observable<Country[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/country/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Country.fromDto(res)))
    );
  }

  /**
   * get all the informations of a specific client by clientId
   * ERRROR 600 -> client not in the database
   * @param clientId client id
   */
  public getClientCompanyInformationsById(clientId: string): Observable<Client> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/client/get', { headers: headers, params: { id: clientId } }).pipe(
      map((res: Client) => {
        return res;
      }));
  }

  /**
   * add new client and company if selected
   * ERROR 600 -> Client username already exist in the database
   * ERROR 601 -> Client first email already exist in the database
   * ERROR 602 -> error inserting the comapany
   * ERROR 500 -> internal server error
   */
  public addClient(client: Client, company?: Company): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/client/insert', { client: client }, { headers }).pipe(
      map((res: any) => {
        console.log(res);
        return res;
      }));
  }

  public updateSpecificClientCompany(client: Client, company?: Company): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/client/update', { client: client }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * enableMultipleClients
   */
  public enableMultipleClients(clientIds: Array<string>): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/client/enable', { clientIds }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * disableMultipleClients
   */
  public disableMultipleClients(clientIds: Array<string>): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/admin/client/disable', { clientIds }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * softDeleteClients
   */
  public softDeleteClients(clientIds: Array<string>): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.request('delete', URL_BASE + '/admin/client/delete', { headers, body: { clientIds } }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
  * getSurveyOptions
  */
  public getSurveyOptions(): Observable<Survey[]> {
    return this._http.get(URL_BASE + '/admin/survey/getOptions').pipe(
      map((result: Survey[]) => result.map(res => Survey.fromDto(res)))
    );
  }

  /**
   * getSurveyResults
   */
  public getSurveyResults(clientsId: string): Observable<SurveyAnswer> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/surveyAnswer/getAnswers', {headers: headers, params: {id: clientsId} }).pipe(
      map((res: SurveyAnswer) => {
        return res;
      }));
  }
}
