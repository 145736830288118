import { Component, OnInit } from '@angular/core';
import { OnlineEvent } from 'src/app/shared/models/OnlineEvent';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EventsService } from '../../shared/events.service';
import { TranslateService } from '@ngx-translate/core';
import { Event } from 'src/app/shared/models/Event';

@Component({
  selector: 'app-advertise-event',
  templateUrl: './advertise-event.component.html',
  styleUrls: ['./advertise-event.component.css']
})
export class AdvertiseEventComponent implements OnInit {


  public onlineEvent = new OnlineEvent();
  public imageUrl: any;
  public showSuccessMsg = false;
  public startTimeArray = new Array();
  public eventEndDate = '';
  private _imageToUpload: File = null;
  private _eventId: string;
  private _event: Event = new Event();
  public startDateToShow = '';
  public endDateToShow = '';
  private _imageRemoved = false;

  constructor(private _eventService: EventsService, private _activatedRoute: ActivatedRoute, private _router: Router,
    private _toastr: ToastrService, private _translate: TranslateService) { }

  ngOnInit() {


    this._activatedRoute.params.subscribe(params => {
      if (!params.eventId) {
        return this._router.navigate(['/event/my-events']);
      }
      this._eventId = params.eventId;
      this._eventService.getEventById(this._eventId).subscribe(result => {
        this._event = result;
        this._eventService.getOnlineEventByEventId(this._eventId).subscribe(resultOnlineEvent => {
          console.log('resultOnlineEvent: ', resultOnlineEvent);
          this.onlineEvent = (resultOnlineEvent ? resultOnlineEvent : new OnlineEvent());
          this.onlineEvent.startDate = this._event.startDate;
          this.startDateToShow = this._event.startDate;
          this.onlineEvent.endDate = moment(this._event.endDate, 'YYYY-MM-DD').add(-1, 'day').format('YYYY-MM-DD');
          this.endDateToShow = this.onlineEvent.endDate;
          if (!this.onlineEvent.eventNameDe) {
            this.onlineEvent.eventNameDe = this._event.eventName;
          }
          if (this.onlineEvent.pictureName) {
            // this.imageUrl = 'http://localhost:3001/onlineEventImages/' + this._event.id + '/' + this.onlineEvent.pictureName;
            // this.imageUrl = 'https://famulusimages.rrsolutionstest.it/' + this._event.id + '/' + this.onlineEvent.pictureName;
            // backend sends me full image path now
            this.imageUrl = this.onlineEvent.pictureName;
          }
          this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe ?
            this.onlineEvent.eventDescriptionDe.replace(/<br\s*[\/]?>/gi, '\n') : '');
          this.onlineEvent.eventDescriptionIt = (this.onlineEvent.eventDescriptionIt ?
            this.onlineEvent.eventDescriptionIt.replace(/<br\s*[\/]?>/gi, '\n') : '');
          const existingHoursArray = (this.onlineEvent.startHour ? JSON.parse(this.onlineEvent.startHour) : undefined);
          if (!existingHoursArray) {
            for (const m = moment(this._event.startDate); m.diff(this._event.endDate, 'days') <= -1; m.add(1, 'days')) {
              this.startTimeArray.push({
                date: m.format('YYYY-MM-DD'),
                time: { hour: 8, minute: 0, second: 0 }
              });
            }
          } else {
            for (const element of existingHoursArray) {
              const timeExplode = element.time.split(':');
              const newHour = (parseInt(timeExplode[0], 10) >= 8) ? parseInt(timeExplode[0], 10) : 8;
              this.startTimeArray.push({
                date: element.date,
                time: { hour: newHour, minute: parseInt(timeExplode[1], 10), second: 0 }
              });
            }
          }
          console.log(this.onlineEvent);
        });
      });
    });
  }

  handleImageFileInput(files: FileList) {
    if (files.length === 0) { return; }
    if (files.length > 1) { return; }
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return this._toastr.warning(this._translate.instant('event.advertiseEvent.imageError'));
    }
    const reader = new FileReader();
    this._imageToUpload = files.item(0);
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imageUrl = reader.result;
    };
    this._imageRemoved = false;
  }

  removeImagePreview() {
    this._imageToUpload = null;
    this.imageUrl = null;
    this.onlineEvent.pictureName = undefined;
    this._imageRemoved = true;
  }


  submitOnlineEvent() {
    console.log('onlineEvent', this.onlineEvent);
    this.onlineEvent.startHour = JSON.stringify(this.startTimeArray);
    this.showSuccessMsg = false;
    let imageName: string;
    if (this._imageToUpload) {
      const imageFileNameArray = this._imageToUpload.name.split('.');
      const imageNameFinale = imageFileNameArray[imageFileNameArray.length - 1].toLocaleLowerCase();
      imageName = Math.random().toString(36).slice(-8) + '.' + imageNameFinale;
      console.log('upload_imageName:', imageName);
    }
    // check if starting hours > 8
    for (const startTime of this.startTimeArray) {
      console.log('startTimes:', startTime);
      const currentHour = startTime.time.hour;
      if (currentHour <= 0) {
        return this._toastr.warning(this._translate.instant('event.advertiseEvent.currentHourError'));
      }
    }
    if (!this.onlineEvent.eventNameDe && !this.onlineEvent.eventNameIt) {
      return this._toastr.warning(this._translate.instant('event.advertiseEvent.missingTitleError'));
    }
    if (this.onlineEvent.eventNameDe) {
      if (this.onlineEvent.eventNameDe.length > 240) {
        return this._toastr.warning(this._translate.instant('event.advertiseEvent.titleDeTooLong'));
      }
    }
    if (this.onlineEvent.eventNameIt) {
      if (this.onlineEvent.eventNameIt.length > 240) {
        return this._toastr.warning(this._translate.instant('event.advertiseEvent.titleItTooLong'));
      }
    }
    this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe) ? this.onlineEvent.eventDescriptionDe.trim() : '';
    this.onlineEvent.eventDescriptionIt = (this.onlineEvent.eventDescriptionIt) ? this.onlineEvent.eventDescriptionIt.trim() : '';
    this._eventService.insertOnlineEvent(this._imageToUpload, imageName, this._eventId, this.onlineEvent,
      this._imageRemoved).subscribe(result => {
      this.onlineEvent = result;
      this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe ?
        this.onlineEvent.eventDescriptionDe.replace(/<br\s*[\/]?>/gi, '\n') : '');
      this.onlineEvent.eventDescriptionDe = (this.onlineEvent.eventDescriptionDe ?
        this.onlineEvent.eventDescriptionDe.replace(/<br\s*[\/]?>/gi, '\n') : '');
      this._toastr.success('Event published successfully');
      this._imageRemoved = false;
    }, error => {
      console.error(error);
    });
  }

  onLocationChange(coordinates) {
    this.onlineEvent.latitude = coordinates.lat;
    this.onlineEvent.longitude = coordinates.long;
  }

  changeOnlineEventPublishValue() {
    if (this.onlineEvent.id) {
      this._eventService.changePublishValue(this.onlineEvent.id.toString(),
        this.onlineEvent.onlinePublish.toString()).subscribe(result => { });
    }
  }
}
