import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceUser } from 'src/app/shared/models/DeviceUser';
import { Option } from 'src/app/shared/select/select.component';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Device } from 'src/app/shared/models/Device';
import { EventsService } from 'src/app/events/shared/events.service';
import { ToastrService } from 'ngx-toastr';
import { EventDetailsComponent } from '../../event-details.component';
import { Event } from 'src/app/shared/models/Event';
import { AdditionalCost } from '../../../../shared/models/AdditionalCost';

@Component({
  selector: 'app-device-users-card',
  templateUrl: './device-users-card.component.html',
  styleUrls: ['./device-users-card.component.css']
})
export class DeviceUsersCardComponent implements OnInit, OnChanges {
  public deviceUser: DeviceUser = new DeviceUser();
  public deviceUserErrorArray = new Array();
  public priceListId = undefined;
  public deviceId = undefined;
  public hideMainContainer = true;
  public hideGenerateDeviceUserBtn = true;
  public showEditContent = false;
  public assignedPriceListOptions: Option[] = [{ value: undefined, label: 'No Price List' }];

  @Input()
  deviceUsers: DeviceUser[];

  @Input()
  assignedPriceListObjects: PriceList[];

  @Input()
  devicesListOptions: Option[];

  @Input()
  availableDevicesObjects: Device[];

  @Input()
  event: Event;

  @Input()
  eventFinished: boolean;


  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeviceUserChange = new EventEmitter<DeviceUser[]>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onDeviceUserGenereted = new EventEmitter<DeviceUser[]>();

  constructor(private _eventsService: EventsService, private _toastr: ToastrService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deviceUsers) {
      if (changes.deviceUsers.currentValue.length === 0) {
        this.hideGenerateDeviceUserBtn = false;
      } else {
        this.hideGenerateDeviceUserBtn = true;
        this.hideMainContainer = false;
      }
    }
    if (changes.assignedPriceListObjects) {
      this.populateEventPriceListSelect(changes.assignedPriceListObjects.currentValue);
    }
  }

  showUpdateDeviceUserContent(deviceUserId: number) {
    this.priceListId = undefined;
    this.deviceId = undefined;
    this.deviceUser = { ...this.deviceUsers.filter(deviceUser => deviceUser.id === deviceUserId)[0] };
    if (this.deviceUser.priceList) {
      this.priceListId = this.deviceUser.priceList.id;
    }
    if (this.deviceUser.device) {
      this.deviceId = this.deviceUser.device.id;
    }
    this.showEditContent = true;
  }

  cancelDeviceUserUpdate() {
    this.priceListId = undefined;
    this.deviceUser = new DeviceUser();
    this.priceListId = undefined;
    this.deviceId = undefined;
    this.showEditContent = false;
  }

  onPriceListChangeEvent() {
    if (this.priceListId !== undefined) {
      this.deviceUser.priceList = this.assignedPriceListObjects.filter(item => item.id === this.priceListId)[0];
    } else {
      this.deviceUser.priceList = null;
    }
  }

  onDeviceChangeEvent() {
    if (this.deviceId !== undefined) {
      this.deviceUser.device = this.availableDevicesObjects.filter(item => item.id === this.deviceId)[0];
    } else {
      this.deviceUser.device = null;
    }
  }

  updateDeviceUser() {
    if (!this.validatePaymentSystems(this.deviceUser)) {
      return;
    }

    if (this.deviceUser.hasFiscalPrinter && !this.validateIPAddress()) {
      return;
    }

    this._eventsService.updateDeviceUser(this.deviceUser, this.event.id.toString()).subscribe(result => {
      this.deviceUser.username = result.username;
      this.deviceUser.affiliateKey = result.affiliateKey;
      this.deviceUser.isvClientId = result.isvClientId;
      this.deviceUser.isvClientSecret = result.isvClientSecret;
      this.deviceUser.hasFiscalPrinter = result.hasFiscalPrinter;
      this.deviceUser.fiscalPrinterIP = result.fiscalPrinterIP;
      this._toastr.success('Device User "' + this.deviceUser.name + '" updated!');
      const existingObject = this.deviceUsers.filter(devUsr => devUsr.id === this.deviceUser.id)[0];
      this.deviceUsers[this.deviceUsers.indexOf(existingObject)] = DeviceUser.fromDto(this.deviceUser);
      this.cancelDeviceUserUpdate();
      this.onDeviceUserChange.emit(this.deviceUsers);
    }, error => {
      console.log(error);
      this._toastr.error('Error updating Device User');
    });
  }

  private validatePaymentSystems(deviceUserToUpdate: DeviceUser): boolean {
    const additionalCosts = this.event.eventAdditionalCosts.map(ac => AdditionalCost.fromDto(ac));
    const sumUpCost = additionalCosts.find(ac => ac.id === 10);
    const vivaWalletCost = additionalCosts.find(ac => ac.id === 9);

    const devicesUserToUpdate = this.deviceUsers.filter(d => d.id !== deviceUserToUpdate.id);
    devicesUserToUpdate.push(deviceUserToUpdate);

    if (sumUpCost) {
      const sumUpDevicesEnabled = devicesUserToUpdate.filter(d => d.enableSumUp);
      if (sumUpDevicesEnabled.length > sumUpCost.quantity) {
        this._toastr.error('Limit of SumUp licenses exceeded for the number of devices');
        return false;
      }
      if (this.deviceUser.enableSumUp && (!this.deviceUser.affiliateKey || this.deviceUser.affiliateKey.trim().length === 0)) {
        this._toastr.error('Affiliate Key is required for SumUp payment system');
        return false;
      }
    }
    if (vivaWalletCost) {
      const vivaWalletDevicesEnabled = devicesUserToUpdate.filter(d => d.enableVivaWallet);
      if (vivaWalletDevicesEnabled.length > vivaWalletCost.quantity) {
        this._toastr.error('Limit of VivaWallet licenses exceeded for the number of devices');
        return false;
      }
      /*
      if (this.deviceUser.enableVivaWallet && (!this.deviceUser.isvClientId || this.deviceUser.isvClientId.trim().length === 0)) {
        this._toastr.error('Client ID is required for VivaWallet payment system');
        return false;
      }
      if (this.deviceUser.enableVivaWallet && (!this.deviceUser.isvClientSecret || this.deviceUser.isvClientSecret.trim().length === 0)) {
        this._toastr.error('Client secret is required for VivaWallet payment system');
        return false;
      }
      */
    }
    return true;
  }

  private validateIPAddress(): boolean {
    const deviceUsersWithIP = this.deviceUsers.filter(d => d.fiscalPrinterIP);
    const devicesIPs = deviceUsersWithIP.map(d => d.fiscalPrinterIP);
    const ipsSet = new Set(devicesIPs);
    const ips = Array.from(ipsSet);

    if (this.event.requestedSoftwareTypes
      .filter(software => software.softwareType.id === 5).length < 1) {
        this._toastr.error('No available Fiscal Printers');
        return false;
      }

    const qtySoftwareFiscalPrinter = this.event.requestedSoftwareTypes
      .filter(software => software.softwareType.id === 5)[0].quantity;

    // validate quantity
    if (ips.length > qtySoftwareFiscalPrinter) {
      this._toastr.error('Limit of Fiscal Printer licenses exceeded');
      return false;
    }

    // validate IP address
    if (ips.some(ip => !this.isValidIPAddress(ip))) {
      this._toastr.error('Invalid IP address');
      return false;
    }

    return true;
  }

  private isValidIPAddress(ipAddress: string): boolean {
    // Regular expression for IPv4
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    // Check if the ipAddress matches IPv4 regex
    return ipv4Regex.test(ipAddress);
  }

  generateNewDeviceUsers() {
    this.hideGenerateDeviceUserBtn = true;
    this.hideMainContainer = true;
    this._eventsService.generateNewDeviceUsersByEventId(this.event.id.toString(), this.event.language).subscribe(newDeviceUsers => {
      this._toastr.success('Generated new Device Users for this Event');
      this.deviceUsers = newDeviceUsers;
      this.hideGenerateDeviceUserBtn = true;
      this.hideMainContainer = false;
      this.onDeviceUserGenereted.emit(newDeviceUsers);
    }, error => {
      this._toastr.error('Error generating new Device Users for this Event');
      console.log(error);
    });
  }

  populateEventPriceListSelect(priceLists: PriceList[]) {
    this.assignedPriceListOptions = [{ value: undefined, label: 'No Price List' }];
    priceLists.forEach(priceList => {
      this.assignedPriceListOptions.push({ value: priceList.id, label: priceList.name });
    });
  }

  logoutDeviceUser(deviceUser: DeviceUser) {
    deviceUser.pinCodeUse = false;
    this._eventsService.updateDeviceUser(deviceUser, this.event.id.toString()).subscribe(result => {
      console.log(result);
      this._toastr.success('Device User "' + deviceUser.name + '" logged out successfully');
    });
  }

}
