import { Printer } from './Printer';
import { PrinterManufacturer } from './PrinterManufacturers';

export class PrinterType {
    public id: number;
    public name: string;
    public lan: boolean;
    public wifi: boolean;
    public usb: boolean;
    public bluetooth: boolean;
    public rescureMode: boolean;
    public status: number;
    public isFiscalPrinter: boolean;
    public printers: Printer[] = [];
    public printerManufacturers: PrinterManufacturer;

    static fromDto(p: any): PrinterType {
        const printerType = new PrinterType();

        if (!p) {
            return null;
        }

        printerType.id = p.id;
        printerType.name = p.name;
        printerType.lan = p.lan;
        printerType.wifi = p.wifi;
        printerType.usb = p.usb;
        printerType.bluetooth = p.bluetooth;
        printerType.rescureMode = p.rescureMode;
        printerType.status = p.status;
        printerType.isFiscalPrinter = p.isFiscalPrinter;
        if (p.printers) {
            printerType.printers = p.printers.map(printer => Printer.fromDto(printer));
        }
        printerType.printerManufacturers = PrinterManufacturer.fromDto(p.printerManufacturers);

        return p;
    }
}
