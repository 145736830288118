import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { RoutersSearchCriteria } from '../shared/RoutersSearchCriteria';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { Router } from 'src/app/shared/models/Router';
import { Observable } from 'rxjs';
import { RoutersService } from '../shared/routers.service';
import { DataTableColumn } from 'src/app/shared/data-table/data-table.component';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { RouterType } from 'src/app/shared/models/RouterType';
import { RouterStatusEnum } from 'src/app/shared/enums/RouterStatusEnum';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-routers-list',
  templateUrl: './routers-list.component.html',
  styleUrls: ['./routers-list.component.css']
})
export class RoutersListComponent implements OnInit {

  public defaultStartDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public defaultEndDate = formatDate(new Date(), 'dd-MM-yyyy', 'en');
  public defaultPageSize = '99999';

  public pageSizeOptionArray: Option[] = [
    { value: '10', label: '10' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '10' },
    { value: '99999', label: 'All' },
  ];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Router>> = null;
  filters: RoutersSearchCriteria = {startdate: this.defaultStartDate, enddate: this.defaultEndDate, pageSize: this.defaultPageSize};
  public routerTypeVals: RouterType[] = [];


  public routerStatusArray: Option[] = [
    { value: RouterStatusEnum.Deleted, label: 'Deleted' },
    { value: RouterStatusEnum.Available, label: 'Available' },
    { value: RouterStatusEnum.Rented, label: 'Rented' },
    { value: RouterStatusEnum.Damaged, label: 'Damaged' }
  ];

  @ViewChild('id', { static: true })
  id: TemplateRef<any>;

  @ViewChild('name', { static: true })
  name: TemplateRef<any>;

  @ViewChild('statusColumn', { static: true })
  statusColumn: TemplateRef<any>;

  @ViewChild('routerType', { static: true })
  routerType: TemplateRef<any>;

  @ViewChild('routerManufacturer', { static: false })
  routerManufacturers: TemplateRef<any>;

  @ViewChild('channel', { static: true })
  channel: TemplateRef<any>;

  @ViewChild('master', { static: true })
  master: TemplateRef<any>;

  @ViewChild('networkName', { static: true })
  networkName: TemplateRef<any>;

  @ViewChild('networkPassword', { static: true })
  networkPassword: TemplateRef<any>;

  @ViewChild('routerIp', { static: true })
  routerIp: TemplateRef<any>;

  @ViewChild('routerUsername', { static: true })
  routerUsername: TemplateRef<any>;

  @ViewChild('routerPassword', { static: true })
  routerPassword: TemplateRef<any>;

  @ViewChild('notes', { static: true })
  notes: TemplateRef<any>;

  @ViewChild('routerAssignedCount', { static: true })
  routerAssignedCount: TemplateRef<any>;

  @ViewChild('assignedEvent', { static: true })
  assignedEvent: TemplateRef<any>;

  @ViewChild('actionsColumn', { static: true })
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _routersService: RoutersService, private _toastr: ToastrService, private _sanitizer: DomSanitizer, private titleService: Title) {
    this.loadPage = (page: number, pageSize: number) => this._routersService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this.titleService.setTitle("Routers - FAdminFrontend");
    this._routersService.getAllRouterTypes().subscribe(result => {
      this.routerTypeVals = result;
    });
    this.columns = [
      {
        name: 'id',
        displayed: true,
        translationKey: 'check',
        template: this.id,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'name',
        displayed: true,
        translationKey: 'name',
        template: this.name,
        sortable: true
      },
      {
        name: 'routerType',
        displayed: true,
        translationKey: 'router type name',
        template: this.routerType,
        sortable: true
      },
      {
        name: 'channel',
        displayed: true,
        translationKey: 'Channel',
        template: this.channel,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'master',
        displayed: true,
        translationKey: 'Master',
        template: this.master,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'networkName',
        displayed: true,
        translationKey: 'Network Name',
        template: this.networkName,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'networkPassword',
        displayed: true,
        translationKey: 'Network Password',
        template: this.networkPassword,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'routerIp',
        displayed: true,
        translationKey: 'Router Ip',
        template: this.routerIp,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'routerUsername',
        displayed: true,
        translationKey: 'Router Username',
        template: this.routerUsername,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'routerPassword',
        displayed: true,
        translationKey: 'Router Password',
        template: this.routerPassword,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'notes',
        displayed: true,
        translationKey: 'Notes',
        template: this.notes,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'statusColumn',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'routerAssignedCount',
        displayed: true,
        translationKey: 'Ass. Count',
        template: this.routerAssignedCount,
        sortable: false
      },
      {
        name: 'assignedEvent',
        displayed: true,
        translationKey: 'Ass. Event',
        template: this.assignedEvent,
        sortable: false
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right'
      }
    ];
  }

  softDeleteRouter(id: string, force: string) {
    this._routersService.deleteRouter(id, force).subscribe(result => {
      this.loadPage = (page: number, pageSize: number) => this._routersService.search(page, pageSize, this.filters);
      this._toastr.success('Router succesfully deleted');
    });
  }

  softDeleteRouterType(id: string, force: string) {
    this._routersService.deleteRouterType(id, force).subscribe(result => {
      this._routersService.getAllRouterTypes().subscribe(result2 => {
        this.routerTypeVals = result2;
        this._toastr.success('Router type succesfully deleted');
      });
    });
  }

  showAssignedEvent (eventString: string): any {
    if (eventString) {
      const html = this._sanitizer.bypassSecurityTrustHtml(eventString.replace(/,/g, '<br />'));
      return html;
    } else {
      return '';
    }
  }
}
