import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { Village } from '../models/Village';
import { Country } from '../models/Country';
import { Region } from '../models/Region';
import { Province } from '../models/Province';
import { forkJoin } from 'rxjs';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-select-location-group',
  templateUrl: './select-location-group.component.html',
  styleUrls: ['./select-location-group.component.css']
})
export class SelectLocationGroupComponent implements OnInit {

  @Input()
  initialCountry: Country;

  @Input()
  initialVillage: Village;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onVillageChosen: EventEmitter<Village> = new EventEmitter<Village>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onCountryChosen: EventEmitter<Country> = new EventEmitter<Country>();

  constructor(private _sharedService: SharedService) {}

  public countryOptions: Option[] = [];
  public villageOptions: Option[] = [];
  public regionOptions: Option[] = [];
  public provinceOptions: Option[] = [];

  public chosenCountry: Option;
  public chosenRegion: Option;
  public chosenProvince: Option;
  public chosenVillage: Option;

  private _countriesObjectArray: Country[] = [];
  private _regionsObjectArray: Region[] = [];
  private _provinceObjectArray: Province[] = [];
  private _villagesObjectArray: Village[] = [];

  public countriesAndVillageLoaded = false;

  ngOnInit() {
    forkJoin([this._sharedService.getAllVillages(), this._sharedService.getAllCountries()]).subscribe({
      next: (res) => {
        this._villagesObjectArray = res[0];
        this._regionsObjectArray = this._villagesObjectArray.map(village => village.province.region);
        this._provinceObjectArray = this._villagesObjectArray.map(village => village.province);
        this._countriesObjectArray = res[1];
        this.countryOptions = this.generateOptions(this._countriesObjectArray);
        if (this.initialVillage) {
          this.chosenCountry = this.countryOptions.find(country => country.value === this.initialVillage.province.region.country.id);
          this.onSelectChange('country');
          this.chosenRegion = this.regionOptions.find(region => region.value === this.initialVillage.province.region.id);
          this.onSelectChange('region');
          this.chosenProvince = this.provinceOptions.find(province => province.value === this.initialVillage.province.id);
          this.onSelectChange('province');
          this.chosenVillage = this.villageOptions.find(village => village.value === this.initialVillage.id);
          this.onSelectChange('village');
        } else if (this.initialCountry && !this.initialVillage) {
          this.chosenCountry = this.countryOptions.find(country => country.value === this.initialCountry.id);
          this.onSelectChange('country');
        }
        this.countriesAndVillageLoaded = true;
      },
      error: () => console.log('Unable to fetch countries and/or villages'),
    });
  }

  public onSelectChange(locationType: 'country' | 'region' | 'province' | 'village') {
    switch (locationType) {
      case 'country':
        this.chosenRegion = this.chosenProvince = this.chosenVillage = undefined;
        this.regionOptions = this.provinceOptions = this.villageOptions = [];
        this.onVillageChosen.emit(undefined);
        if (!this.chosenCountry) {
          this.onCountryChosen.emit(undefined);
        } else {
          this.onCountryChosen.emit(
            this._countriesObjectArray.find(country => country.id === this.chosenCountry.value)
          );
          if (this.chosenCountry.value === 1) {
            this.regionOptions = this.generateOptions(
              this._regionsObjectArray.filter((region, index, self) => {
                return region.country.id === this.chosenCountry.value && index === self.findIndex(r => r.id === region.id);
              })
            );
          }
        }
        break;
      case 'region':
        this.provinceOptions = this.villageOptions = [];
        this.chosenProvince = this.chosenVillage = undefined;
        this.onVillageChosen.emit(undefined);
        if (this.chosenRegion) {
          this.provinceOptions = this.generateOptions(
            this._provinceObjectArray.filter((province, index, self) => {
              return province.region.id === this.chosenRegion.value && index === self.findIndex(p => p.id === province.id);
            })
          );
        }
        break;
      case 'province':
        this.villageOptions = [];
        this.chosenVillage = undefined;
        this.onVillageChosen.emit(undefined);
        if (this.chosenProvince) {
          this.villageOptions = this.generateOptions(
            this._villagesObjectArray.filter((village, index, self) => {
              return village.province.id === this.chosenProvince.value && index === self.findIndex(v => v.id === village.id);
            })
          );
        }
        break;
      case 'village':
        if (this.chosenVillage) {
          this.onVillageChosen.emit(
            this._villagesObjectArray.find(village => village.id === this.chosenVillage.value)
          );
        }
        break;
    }
  }

  private generateOptions(array: any[]): Option[] {
    return array.map((obj) => {
      return {label: obj.name, value: obj.id};
    });
  }

}
