import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Province } from 'src/app/shared/models/Province';
import { ProvincesService } from './shared/provinces.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-provinces',
  templateUrl: './provinces.component.html',
  styleUrls: ['./provinces.component.css']
})

export class ProvincesComponent implements OnInit {

  public choosenEditProvince: Province = new Province();
  private _choosenProvinceId: number;

  @Input()
  province: Province;

  @Input()
  provincesList: Province[];

  @Input()
  regionsList: Option[];

  @Input()
  regionsOptions: Option[];

  @ViewChild('confirmDeleteProvinceDialog', { static: true })
  confirmDeleteProvinceDialog;

  constructor(private _provincesService: ProvincesService, private _toastr: ToastrService) { }

  ngOnInit() {
  }

  enableEditProvince(province: Province) {
    this.choosenEditProvince = { ...province };
  }

  resetProvincesList(provinceListResponse: Province[]) {
    this.provincesList = provinceListResponse ;
  }

  cancelProvinceEditMode() {
    this.choosenEditProvince = new Province();
  }

  getDeleteProvinceDecision(decision: boolean) {
    if (decision) {
      this._provincesService.deleteProvince(this._choosenProvinceId).subscribe(result => {
      const index = this.provincesList.findIndex(province => province.id === this._choosenProvinceId);
      this.provincesList.splice(index, 1);
        this._toastr.success('Province successfully deleted');
      }, error => {
        console.log(error);
      });
    }
    this._choosenProvinceId = undefined;
  }

  showConfirmDeleteDialog(provinceId: number) {
    this._choosenProvinceId = provinceId;
    this.confirmDeleteProvinceDialog.openDialog();
  }

}

