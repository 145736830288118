import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompaniesService } from '../shared/companies.service';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { CompanyStatusEnum } from 'src/app/shared/enums/CompanyStatusEnum';
import { Company } from 'src/app/shared/models/Company';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { Village } from 'src/app/shared/models/Village';
import { Country } from '../../shared/models/Country';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.css']
})
export class UpdateCompanyComponent implements OnInit {

  public chosenCountry: Country;
  public chosenVillage: Village;

  public companyFormGroup: FormGroup;
  public companyStatusArray: Option[] = [
    { label: 'Deleted', value: CompanyStatusEnum.Deleted.toString() },
    { label: 'Enabled', value: CompanyStatusEnum.Enabled.toString() },
    { label: 'Disabled', value: CompanyStatusEnum.Disabled.toString() }
  ];
  public companyNameRequireError = false;
  public firstEmailRequireError = false;
  public countryRequireError = false;
  public companyId: string;
  public dataFetched = false;
  private _method: string;

  constructor(
    private _route: ActivatedRoute, private _formBuilder: FormBuilder, private _companiesService: CompaniesService, private titleService: Title,
    private _toastr: ToastrService, private _router: Router) {
    this.companyFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', [<any>Validators.required, <any>Validators.minLength(1)]],
      firstEmail: ['', [<any>Validators.required, <any>Validators.email]],
      secondEmail: ['', <any>Validators.email],
      firstPhone: [''],
      secondPhone: [''],
      fiscalCode: [''],
      vat: [''],
      pecEmail: ['', <any>Validators.email],
      isdNumber: [''],
      address: [''],
      postalCode: [''],
      city: [''],
      country: ['', [<any>Validators.required]],
      status: ['1'],
      notes: [''],
      village: ['']
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._method = params['methodType'];
      if (this._method !== 'add' && this._method !== 'edit') {
        this._router.navigate(['/companies']);
      }
      this.companyId = params['companyId'];
      this.titleService.setTitle("[" + this.companyId + "] Edit Company - FAdminFrontend");
      if (this._method === 'edit') {
        this._companiesService.getCompanyInformationsById(this.companyId).subscribe(company => {
          this.companyFormGroup.patchValue(company);
          this.chosenCountry = company.country;
          if (company.village) {
            company.village.province.region.country = company.country;
            this.chosenVillage = company.village;
          }
          this.dataFetched = true;
        });
      }
    });
  }

  onCompanyFormSubmit() {
    this.companyNameRequireError = false;
    this.firstEmailRequireError = false;
    this.countryRequireError = false;
    if (this.companyFormGroup.valid) {
      const formCompanyObject = Company.fromDto(this.companyFormGroup.value);
      formCompanyObject.country = this.chosenCountry;
      if (this.chosenVillage) {
        formCompanyObject.village = this.chosenVillage;
      } else {
        formCompanyObject.village = null;
      }
      if (this._method === 'edit') {
        this._companiesService.updateComapny(formCompanyObject).subscribe(result => {
          this._toastr.success('', 'Company updated');
        });
      } else {
        this._companiesService.insertNewCompany(formCompanyObject).subscribe(result => {
          this._toastr.success('', 'Company added');
          this._router.navigate(['/companies']);
        });
      }
    }
    const formValidationErrores = ValidationUtils.getFormValidationErrors(this.companyFormGroup, '');
    if (formValidationErrores['name']) {
      this.companyNameRequireError = true;
    }
    if (formValidationErrores['firstEmail']) {
      this.firstEmailRequireError = true;
    }
    if (formValidationErrores['country']) {
      this.countryRequireError = true;
    }
  }

}
