import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Printer } from 'src/app/shared/models/Printer';
import { Option } from 'src/app/shared/select/select.component';
import { Event } from 'src/app/shared/models/Event';
import { EventsService } from 'src/app/events/shared/events.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-printers-card',
  templateUrl: './printers-card.component.html',
  styleUrls: ['./printers-card.component.css']
})
export class PrintersCardComponent implements OnInit {

  public printerId = undefined;
  public selectedPrinter: Printer = new Printer();
  public testArray: Printer[];
  public realPrinters: Printer[];

  @Input()
  printers: Printer = new Printer();

  @Input()
  availablePrinterOptions: Option[];

  @Input()
  availablePrinterObjects: Printer[];

  @Input()
  event: Event = new Event();

  @Input()
  eventFinished = false;

  @Input()
  freeRealPrinterAmount = 0;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPrinterAddEvent = new EventEmitter<void>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPrinterRemoveEvent = new EventEmitter<void>();

  constructor(private _eventsService: EventsService, private _route: ActivatedRoute,
    private _toastr: ToastrService, private _translate: TranslateService) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setPrinters();
  }

  printerSelectChange() {
    if (this.selectedPrinter !== undefined) {
      this.selectedPrinter = this.availablePrinterObjects.filter(printer => printer.id === this.printerId)[0];
    } else {
      this.selectedPrinter = new Printer();
    }
    this.setPrinters();
  }

  associatePrinterToEventClick() {
    let maxAmount = 0;
    if (this.printerId) {
      this.event.requestedSoftwareTypes.forEach(requestedSoftwareType => {
        if (requestedSoftwareType.softwareType.id === 4) {
          maxAmount += requestedSoftwareType.quantity;
        }
      });
      if (this.realPrinters.length <= (maxAmount - 1)) {
        this.event.printers.push(this.selectedPrinter);
        this._eventsService.assignPrinterToEventByIds(this.event.id.toString(), this.event.printers).subscribe(result => {
          this.selectedPrinter = new Printer();
          this.printerId = undefined;
          this.onPrinterAddEvent.emit();
        }, error => {
          console.log(error);
        });
      } else {
        this._toastr.warning(this._translate.instant('Cannot exceed the requested number of printers'));
      }
    }
    this.setPrinters();
  }

  unAssociatePrinterFromEventClick(printer) {
    this.printerId = printer.id;
    this._eventsService.unAssignPrinterFromEventById(this.event.id.toString(), [printer]).subscribe(result => {
      this.selectedPrinter = new Printer();
      this.printerId = undefined;
      this._removePrinterFromArray(printer.id);
      this.onPrinterRemoveEvent.emit();
    }, error => {
      console.log(error);
    });
    this.setPrinters();
  }

  private _removePrinterFromArray(printerId) {
    const requestedPrinter = this.event.printers.filter(currentPrinter => currentPrinter.id === printerId)[0];
    const index = this.event.printers.indexOf(requestedPrinter);
    this.event.printers.splice(index, 1);
    this.setPrinters();
  }

  public setPrinters() {
    this.realPrinters = this.event.printers.filter(printer => (!printer.printerType.isFiscalPrinter));
  }
}
