import { Survey } from './Survey';
import { Client } from './Client';

export class SurveyAnswer {
    public surveyId: number;
    public description: string;
    public client: Client;
    public survey: Survey;

    static fromDto(c): SurveyAnswer {
        const surveyAnswer = new SurveyAnswer();
        if (!c) {
            return null;
        }
        surveyAnswer.surveyId = c.surveyId;
        surveyAnswer.description = c.description;
        surveyAnswer.client = Client.fromDto(c.clientUserId);
        surveyAnswer.survey = Survey.fromDto(c.surveyAnswerId);
        return c;
    }
}
