import { Client } from './Client';
import { RequestedDevice } from './RequestedDevice';
import { RequestedPrinterType } from './RequestedPrinterType';
import { RequestedUps } from './RequestedUps';
import { RequestedCable } from './RequestedCable';
import { PriceList } from './PriceList';
import { RequestedRouterType } from './RequestedRouterType';
import { RequestedSoftwareType } from './RequestedSoftwareType';
import { Printer } from './Printer';
import { DeviceUser } from './DeviceUser';
import { Router } from './Router';
import { PrintersCategories } from './PrintersCategories';
import { Village } from './Village';
import { PickupPoint } from './PickupPoint';
import { DeliveryAddress } from './DeliveryAddress';
import { OnlineEvent } from './OnlineEvent';
import { AdditionalCost, AdditionalCostsSaveOffer } from './AdditionalCost';

export class Event {
    public id: number;
    public eventName: string;
    public startDate: string;
    public endDate: string;
    public startTime: string;
    public endTime: string;
    public pickUpDate: string;
    public returnDate: string;
    public pickUpTime: string;
    public returnTime: string;
    public createdAt: string;
    public status: number;
    public prepraingDate: string;
    public preparedDate: string;
    public language: string;
    public zone: string;
    public latitude: string;
    public longitude: string;
    public onlinePublish: boolean;
    public clientUser: Client = new Client();
    public requestedPrinterTypes: RequestedPrinterType[] = [];
    public requestedUps: RequestedUps[] = [];
    public requestedCable: RequestedCable[] = [];
    public requestedDevices: RequestedDevice[] = [];
    public priceLists: PriceList[] = [];
    public requestedRouterTypes: RequestedRouterType[] = [];
    public requestedSoftwareTypes: RequestedSoftwareType[] = [];
    public printers: Printer[] = [];
    public deviceUsers: DeviceUser[] = [];
    public routers: Router[] = [];
    public printersCategories: PrintersCategories[] = [];
    public village: Village;
    public delivery: boolean;
    public pickupPoint: PickupPoint;
    public deliveryAddress: DeliveryAddress;
    public onlineEvent: OnlineEvent;
    public rrPrinters: Printer[] = [];
    public eventAdditionalCosts: AdditionalCost[] = [];
    public additionalCosts: AdditionalCostsSaveOffer[] = []; // only for save when create new offer
    public hasVivaWallet: boolean;
    public hasSumUp: boolean;

    static fromDto(e): Event {
        const event = new Event();

        if (!e) {
            return null;
        }
        event.id = e.id;
        event.eventName = e.eventName;
        event.startDate = e.startDate;
        event.endDate = e.endDate;
        event.startTime = e.startTime;
        event.endTime = e.endTime;
        event.pickUpDate = e.pickUpDate;
        event.returnDate = e.returnDate;
        event.pickUpTime = e.pickUpTime;
        event.returnTime = e.returnTime;
        event.createdAt = e.createdAt;
        event.status = e.status;
        event.prepraingDate = e.prepraingDate;
        event.preparedDate = e.preparedDate;
        event.language = e.language;
        event.zone = e.zone;
        event.latitude = e.latitude;
        event.longitude = e.longitude;
        event.onlinePublish = e.onlinePublish;
        event.clientUser = Client.fromDto(e.clientUser);
        event.hasSumUp = e.hasSumUp;
        event.hasVivaWallet = e.hasVivaWallet;
        if (e.requestedPrinterTypes) {
            event.requestedPrinterTypes = e.requestedPrinterTypes.map(printerTypes => RequestedPrinterType.fromDto(printerTypes));
        }
        if (e.requestedUps) {
            event.requestedUps = e.requestedUps.map(ups => RequestedUps.fromDto(ups));
        }
        if (e.requestedCable) {
            event.requestedCable = e.requestedCable.map(cable => RequestedCable.fromDto(cable));
        }
        if (e.requestedDevices) { event.requestedDevices = e.requestedDevices.map(device => RequestedDevice.fromDto(device)); }
        if (e.priceLists) { event.priceLists = e.priceLists.map(priceList => PriceList.fromDto(priceList)); }
        if (e.requestedRouterTypes) {
            event.requestedRouterTypes = e.requestedRouterTypes.map(routerType => RequestedRouterType.fromDto(routerType));
        }
        if (e.requestedSoftwareTypes) {
            event.requestedSoftwareTypes = e.requestedSoftwareTypes.map(softwareType => RequestedSoftwareType.fromDto(softwareType));
        }
        if (e.printers) {
            event.printers = e.printers.map(printer => Printer.fromDto(printer));
        }
        if (e.deviceUsers) {
            event.deviceUsers = e.deviceUsers.map(deviceUser => DeviceUser.fromDto(deviceUser));
        }
        if (e.routers) {
            event.routers = e.routers.map(router => Router.fromDto(router));
        }
        if (e.printersCategories) {
            event.printersCategories = e.printersCategories.map(printerCategory => PrintersCategories.fromDto(printerCategory));
        }
        event.village = Village.fromDto(e.village);
        event.delivery = e.delivery;
        if (e.pickupPoint) {
            event.pickupPoint = PickupPoint.fromDto(e.pickupPoint);
        }
        if (e.deliveryAddress) {
            event.deliveryAddress = DeliveryAddress.fromDto(e.deliveryAddress);
        }
        if (e.onlineEvent) {
            event.onlineEvent = OnlineEvent.fromDto(e.onlineEvent[0]);
        }
        if (e.rrPrinters) {
          event.rrPrinters = e.rrPrinters.map(rrPrinter => Printer.fromDto(rrPrinter));
        }
        if (e.eventAdditionalCosts) {
          event.eventAdditionalCosts = e.eventAdditionalCosts.map(eventAdditionalCost => AdditionalCost.fromDto(eventAdditionalCost));
        }

        return event;
    }
}
