import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { Company } from 'src/app/shared/models/Company';
import { CompaniesService } from '../shared/companies.service';
import { ToastrService } from 'ngx-toastr';
import { Village } from 'src/app/shared/models/Village';
import { SharedService } from 'src/app/shared/shared.service';
import { Printer } from 'src/app/shared/models/Printer';
import { PrinterStatusEnum } from 'src/app/shared/enums/PrinterStatusEnums';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {

  public company: Company = new Company();
  public printerListOptions: Option[];
  public availablePrinterObjects: Printer[];
  public freeRealPrinterAmount = 0;
  public printerId = undefined;
  public requestedPrinter: Printer;
  private _currentCompanyId: string;

  public printerStatusArray: Option[] = [
    { value: undefined, label: 'ALL' },
    { value: PrinterStatusEnum.Available, label: 'Available' },
    { value: PrinterStatusEnum.Damaged, label: 'Damaged' },
    { value: PrinterStatusEnum.Deleted, label: 'Deleted' },
    { value: PrinterStatusEnum.Rented, label: 'Rented' },
  ];



  @ViewChild('confirmDeleteCompanyDialog', { static: true })
  confirmDeleteCompanyDialog;

  constructor(private _route: ActivatedRoute, private _companiesService: CompaniesService, private _router: Router, private titleService: Title,
    private _toastr: ToastrService, private _sharedService: SharedService) { }

  ngOnInit() {
    this.populatePrinterSelect();
    this._route.params.subscribe(params => {
      this._currentCompanyId = params['companyId'];
      this._companiesService.getCompanyInformationsById(this._currentCompanyId).subscribe(result => {
        this.company = result;
        this.titleService.setTitle("[" + this.company.name + "] Company Details - FAdminFrontend");
      }, error => {
        if (error.status === 600) {
          this._router.navigate(['/companies']);
        }
      });
    });
  }

  hardDeleteCompany() {
    this.confirmDeleteCompanyDialog.openDialog();
  }

  getDeleteCompanyDecision(decision: boolean) {
    if (decision) {
      this._companiesService.hardDeleteCompany(this._currentCompanyId).subscribe(result => {
        this._toastr.success('Company deleted successfuly');
        this._router.navigate(['/companies']);
      });
    }
  }

  populatePrinterSelect() {
    this.printerListOptions = [{ value: undefined, label: 'Select a Printer' }];
    this._companiesService.getAllPrinters().subscribe(printers => {
      this.availablePrinterObjects = printers;
      this.availablePrinterObjects.forEach(printer => {
        if (printer.status.toString() === '1') {
          this.requestedPrinter = this.company.printers.filter(currentPrinter => currentPrinter.id === printer.id)[0];
          if (this.requestedPrinter === undefined) {
            this.printerListOptions.push({ value: printer, label: printer.name });
          }
        }
      });
    }, error => {
      console.log(error);
    });
  }

  associatePrinterToCompanyClick(choosenPrinter) {
    const foundPrinter = this.company.printers.filter(printer => printer.id === choosenPrinter.id)[0];
    if (!foundPrinter) {
      this.company.printers.push(choosenPrinter);
    } else {
      this._toastr.error('Printer already assigned!');
    }
    this._companiesService.assignPrinterToCompanyByIds(this.company.id.toString(), this.company.printers).subscribe(result => {
      if (!foundPrinter) {
        this._toastr.success('Printer successfully added!');
      }
    }, error => {
      console.log(error);
    });
  }

  unAssociatePrinterFromCompanyClick(printer) {
    this.printerId = printer.id;
    this._companiesService.unAssignPrinterFromCompanyById(this.company.id.toString(), [printer]).subscribe(result => {
      this.printerId = undefined;
      this._removePrinterFromArray(printer.id);
      this._toastr.success('Printer successfully removed!');
    }, error => {
      console.log(error);
    });
  }

  private _removePrinterFromArray(printerId) {
    const requestedPrinter = this.company.printers.filter(currentPrinter => currentPrinter.id === printerId)[0];
    const index = this.company.printers.indexOf(requestedPrinter);
    this.company.printers.splice(index, 1);
  }
}
