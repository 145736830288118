import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { saveAs } from 'file-saver';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { OfferService } from '../shared/offer.service';
import { DataTableColumn } from 'src/app/shared/data-table/data-table.component';
import { EventOfferSearchCriteria } from '../shared/EventOfferSearchCriteria';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { EventOfferStatusEnum } from 'src/app/shared/enums/EventOfferStatusEnum';
import { Title } from '@angular/platform-browser';

const colors: any = {
  red: {
    primary: '#ed969e',
    secondary: '#ed969e'
  },
  green: {
    primary: '#8fd19e',
    secondary: '#8fd19e'
  },
  yellow: {
    primary: '#856404',
    secondary: '#856404'
  }
};


@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.css']
})
export class OffersListComponent implements OnInit {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<EventOffer>> = null;
  filters: EventOfferSearchCriteria = {};

  @ViewChild('offerIdColumn', { static: true })
  offerIdColumn: TemplateRef<any>;

  @ViewChild('eventNameColumn', { static: true })
  eventNameColumn: TemplateRef<any>;

  @ViewChild('versionLetterColumn', { static: true })
  versionLetterColumn: TemplateRef<any>;

  @ViewChild('statusColumn', { static: true })
  statusColumn: TemplateRef<any>;

  @ViewChild('startDateColumn', { static: true })
  startDateColumn: TemplateRef<any>;

  @ViewChild('endDateColumn', { static: true })
  endDateColumn: TemplateRef<any>;

  @ViewChild('clientNameColumn', { static: true })
  clientNameColumn: TemplateRef<any>;

  @ViewChild('organizationNameColumn', { static: true })
  organizationNameColumn: TemplateRef<any>;

  @ViewChild('villageColumn', { static: true })
  villageColumn: TemplateRef<any>;

  @ViewChild('zoneColumn', { static: true })
  zoneColumn: TemplateRef<any>;

  @ViewChild('fullPriceColumn', { static: true })
  fullPriceColumn: TemplateRef<any>;

  @ViewChild('discountedPriceColumn', { static: true })
  discountedPriceColumn: TemplateRef<any>;

  @ViewChild('createdAtColumn', { static: true })
  createdAtColumn: TemplateRef<any>;

  @ViewChild('documentsColumn', { static: true })
  documentsColumn: TemplateRef<any>;

  @ViewChild('actionsColumn', { static: true })
  actionsColumn: TemplateRef<any>;

  @ViewChild('confirmDeleteOfferDialog', { static: true })
  confirmDeleteOfferDialog;

  columns: DataTableColumn[];
  public viewDate: Date = new Date();
  public view: CalendarView = CalendarView.Month;
  public eventOffersCalendare: CalendarEvent[] = [];
  public eventOffers: EventOffer[] = [];
  public eventOffersTable: any[] = [];
  public aviableHardwareCount: any;
  public showResetDayButton = false;
  public showResetEventOfferFilterButton = false;
  public choosenEventOfferId: number;
  public currentTabName = 'calendar';

  private _tempEventOffersTable: any[] = [];
  public _choosenOfferId: string;

  constructor(private _offerService: OfferService, private _toastr: ToastrService, private titleService: Title) {
  }

  ngOnInit() {
    this.titleService.setTitle("Event Offers - FAdminFrontend")
    this._offerService.getAviableHardwareCount().subscribe(result => {
      this.aviableHardwareCount = result;
      this._offerService.getEventOffersByMonth(moment(this.viewDate).format('MM'), moment(this.viewDate).format('YYYY'))
        .subscribe(eventOffers => {
          this.eventOffers = eventOffers;
          this._drawTableCalendar();
        });
    });
    this.columns = [
      {
        name: 'offerIdColumn',
        displayed: true,
        translationKey: 'Offer ID',
        template: this.offerIdColumn,
        sortable: true
      },
      {
        name: 'versionLetterColumn',
        displayed: true,
        translationKey: 'Version',
        template: this.versionLetterColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'eventNameColumn',
        displayed: true,
        translationKey: 'Event Name',
        template: this.eventNameColumn,
        sortable: true
      },
      {
        name: 'statusColumn',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true
      },
      {
        name: 'startDateColumn',
        displayed: true,
        translationKey: 'Start date',
        template: this.startDateColumn,
        sortable: true
      },
      {
        name: 'endDateColumn',
        displayed: true,
        translationKey: 'End date',
        template: this.endDateColumn,
        sortable: true
      },
      {
        name: 'clientNameColumn',
        displayed: true,
        translationKey: 'Client name',
        template: this.clientNameColumn,
        sortable: true
      },
      {
        name: 'organizationNameColumn',
        displayed: true,
        translationKey: 'Organization name',
        template: this.organizationNameColumn,
        sortable: true
      },
      {
        name: 'villageColumn',
        displayed: true,
        translationKey: 'Village',
        template: this.villageColumn,
        sortable: true
      },
      {
        name: 'zoneColumn',
        displayed: true,
        translationKey: 'Zone',
        template: this.zoneColumn,
        sortable: true
      },
      {
        name: 'fullPriceColumn',
        displayed: true,
        translationKey: 'Full Price',
        template: this.fullPriceColumn,
        sortable: true
      },
      {
        name: 'discountedPriceColumn',
        displayed: true,
        translationKey: 'Client Price',
        template: this.discountedPriceColumn,
        sortable: true
      },
      {
        name: 'createdAtColumn',
        displayed: true,
        translationKey: 'Created at',
        template: this.createdAtColumn,
        sortable: true
      },
      {
        name: 'documentsColumn',
        displayed: true,
        translationKey: '',
        template: this.documentsColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'actionsColumn',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      }
    ];
  }

  downloadEventOfferPdf(offerId: string, versionLetter: string) {
    this._offerService.downloadEventOfferPdf(offerId, versionLetter).subscribe(result => {
      saveAs(result, offerId + '_' + versionLetter + '.pdf');
    }, error => {
      console.log(error);
      if (error.status) {
        this._toastr.warning('The PDF is creating. Please wait.');
      }
    });
  }

  onTabChange(tabName: string) {
    this.currentTabName = tabName;
    switch (tabName) {
      case 'calendar':
        this._drawTableCalendar();
        break;
      case 'accepted':
        this.filters.status = EventOfferStatusEnum.Accepted.toString();
        this.loadPage = (page: number, pageSize: number) => this._offerService.search(page, pageSize, this.filters);
        break;
      case 'denayed':
        this.filters.status = EventOfferStatusEnum.Canceled.toString() + ',' + EventOfferStatusEnum.Rejected.toString();
        this.loadPage = (page: number, pageSize: number) => this._offerService.search(page, pageSize, this.filters);
        break;
      default:
        this.filters.status = EventOfferStatusEnum.Pending.toString();
        this.loadPage = (page: number, pageSize: number) => this._offerService.search(page, pageSize, this.filters);
        break;
    }
  }

  onAfterTableReset() {
    switch (this.currentTabName) {
      case 'accepted':
        this.filters.status = EventOfferStatusEnum.Accepted.toString();
        break;
      case 'denayed':
        this.filters.status = EventOfferStatusEnum.Canceled.toString() + ',' + EventOfferStatusEnum.Rejected.toString();
        break;
      default:
        this.filters.status = EventOfferStatusEnum.Pending.toString();
        break;
    }
  }

  changeMonth() {
    this.resetDayFilterButtonClick();
    this._offerService.getEventOffersByMonth(moment(this.viewDate).format('MM'), moment(this.viewDate).format('YYYY'))
      .subscribe(eventOffers => {
        this.eventOffers = eventOffers;
        this._drawTableCalendar();
      });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (events.length > 0) {
      const choosenDay = moment(date).format('YYYY-MM-DD');
      if (this._len(this._tempEventOffersTable) === 0) {
        this._tempEventOffersTable = this.eventOffersTable;
      }
      this.eventOffersTable = [];
      this.eventOffersTable[choosenDay] = [];
      for (const key in this._tempEventOffersTable) {
        if (this._tempEventOffersTable.hasOwnProperty(key)) {
          const element = this._tempEventOffersTable[key];
          if (key === choosenDay) {
            element.forEach(offer => {
              this.eventOffersTable[choosenDay].push(offer);
            });
          }
        }
      }
      this.showResetDayButton = true;
    }
  }

  resetDayFilterButtonClick() {
    this.eventOffersTable = this._tempEventOffersTable;
    this._tempEventOffersTable = [];
    this.showResetDayButton = false;
  }

  onEventOfferClick(eventOfferId: number) {
    this.choosenEventOfferId = eventOfferId;
    this.showResetEventOfferFilterButton = true;
  }

  onEventOfferResetClick() {
    this.choosenEventOfferId = undefined;
    this.showResetEventOfferFilterButton = false;
  }

  private _drawTableCalendar(eventOfferId?: number) {
    this.eventOffersCalendare = [];
    this.eventOffersTable = [];
    console.log(this.eventOffers);
    if (this.eventOffers.length > 0) {
      this.eventOffers.forEach(eventOffer => {
        if (eventOffer.status === EventOfferStatusEnum.Accepted || eventOffer.status === EventOfferStatusEnum.Pending) {
          const object = {
            id: eventOffer.id,
            start: new Date(eventOffer.startDate),
            end: new Date(eventOffer.endDate),
            title: eventOffer.eventName,
            color: colors.yellow,
            allDay: true
          };
          switch (eventOffer.status) {
            case EventOfferStatusEnum.Accepted:
              object.color = colors.green;
              break;
            case EventOfferStatusEnum.Pending:
              object.color = colors.yellow;
              break;
            default:
              object.color = colors.red;
              break;
          }
          this.eventOffersCalendare = [...this.eventOffersCalendare, object];
          const dateDiff = moment(eventOffer.returnDate).diff(eventOffer.pickUpDate, 'days') + 1;
          if (dateDiff === 1) {
            this._addNewEventOffer(eventOffer, 0, 0);
          } else {
            for (let index = 0; index < dateDiff; index++) {
              this._addNewEventOffer(eventOffer, index, dateDiff);
            }
          }
        }
      });
    }
  }

  private _addNewEventOffer(eventOffer: EventOffer, daysToAdd: number, daysDiff: number) {
    let arrowColor = '';
    switch (eventOffer.status) {
      case EventOfferStatusEnum.Accepted:
        arrowColor = colors.green.primary;
        break;
      case EventOfferStatusEnum.Pending:
        arrowColor = colors.yellow.primary;
        break;
      default:
        arrowColor = colors.red.primary;
        break;
    }
    const startDateString = moment(eventOffer.pickUpDate).add(daysToAdd, 'day').format('YYYY-MM-DD');
    if (!this.eventOffersTable[startDateString]) {
      this.eventOffersTable[startDateString] = [];
    }
    this.eventOffersTable[startDateString].pop();
    this.eventOffersTable[startDateString].pop();
    const object = {
      id: eventOffer.id,
      status: eventOffer.status,
      eventName: eventOffer.eventName,
      hardwarePrinter: eventOffer.hardwarePrinter,
      hardwarePhone: eventOffer.hardwarePhone,
      hardwareTablet: eventOffer.hardwareTablet,
      hardwareRouter: eventOffer.hardwareRouter,
      rowColorClass: '',
      arrowColor: arrowColor,
      textColorClass: ((daysToAdd + 1) === daysDiff ? 'text-success' : 'text-danger'),
      returnDate: ((daysToAdd + 1) === daysDiff),
      colspanName: '',
      offerQuantity: true,
      icon: ((daysToAdd + 1) === daysDiff ? 'fa-arrow-up' : 'fa-arrow-down'),
      action: {}
    };
    if ((daysToAdd + 1) === 1) {
      object.action = { iconClass: 'fa-dolly text-danger', actionName: 'Pick Up' };
    } else if ((daysToAdd + 1) === daysDiff) {
      object.action = { iconClass: 'fa-dolly text-success', actionName: 'Return' };
    } else {
      if (moment(startDateString) >= moment(eventOffer.startDate) && moment(startDateString) <= moment(eventOffer.endDate)) {
        object.action = { iconClass: 'fa-calendar-check', actionName: 'Event Day' };
      }
    }
    this.eventOffersTable[startDateString].push(object);
    this.eventOffersTable[startDateString].push(this._hardwareOutCounter(startDateString));
    this.eventOffersTable[startDateString].push(this._getHardwareAviabilityCountArray(startDateString));
  }

  private _getHardwareAviabilityCountArray(startDateString: string) {
    const counterElement = {
      id: undefined,
      status: undefined,
      eventName: undefined,
      hardwarePrinter: this.aviableHardwareCount.printerCount,
      hardwarePhone: this.aviableHardwareCount.phoneCount,
      hardwareTablet: this.aviableHardwareCount.tabletCount,
      hardwareRouter: this.aviableHardwareCount.routerCount,
      rowColorClass: 'sum-row',
      arrowColor: '',
      textColorClass: '',
      returnDate: false,
      colspanName: 'Hardware Aviability',
      offerQuantity: false,
      icon: '',
      action: ''
    };
    this.eventOffersTable[startDateString].forEach(element => {
      if (element.offerQuantity) {
        counterElement.hardwarePrinter -= (!element.returnDate ? element.hardwarePrinter : 0);
        counterElement.hardwarePhone -= (!element.returnDate ? element.hardwarePhone : 0);
        counterElement.hardwareTablet -= (!element.returnDate ? element.hardwareTablet : 0);
        counterElement.hardwareRouter -= (!element.returnDate ? element.hardwareRouter : 0);
      }
    });
    return counterElement;
  }

  private _hardwareOutCounter(startDateString: string) {
    const counterElement = {
      id: undefined,
      status: undefined,
      eventName: undefined,
      hardwarePrinter: 0,
      hardwarePhone: 0,
      hardwareTablet: 0,
      hardwareRouter: 0,
      rowColorClass: '',
      arrowColor: '',
      textColorClass: '',
      returnDate: false,
      colspanName: 'Hardware OUT',
      offerQuantity: false,
      icon: '',
      action: ''
    };
    this.eventOffersTable[startDateString].forEach(element => {
      if (element.offerQuantity) {

        counterElement.hardwarePrinter += (!element.returnDate ? element.hardwarePrinter : 0);
        counterElement.hardwarePhone += (!element.returnDate ? element.hardwarePhone : 0);
        counterElement.hardwareTablet += (!element.returnDate ? element.hardwareTablet : 0);
        counterElement.hardwareRouter += (!element.returnDate ? element.hardwareRouter : 0);

      }
    });
    return counterElement;
  }

  private _len(arr) {
    let count = 0;
    for (const k in arr) {
      if (arr.hasOwnProperty(k)) {
        count++;
      }
    }
    return count;
  }

  onDeletePriceListClickEvent(priceListId: string) {
    this._choosenOfferId = priceListId;
    this.confirmDeleteOfferDialog.openDialog();
  }

  deleteEventOffer(decision) {
    if (decision) {
      this._offerService.deleteEventOffer(this._choosenOfferId).subscribe(result => {
        this.loadPage = (page: number, pageSize: number) => this._offerService.search(page, pageSize, this.filters);
        this._toastr.success('Event Offer deleted permamentaly');
      });
    } else {
      this._choosenOfferId = undefined;
    }
  }
}
