import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { saveAs } from 'file-saver';
import { PriceListService } from '../shared/price-list.service';
import { PriceList } from 'src/app/shared/models/PriceList';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { Observable } from 'rxjs';
import { SearchObjectResult } from 'src/app/shared/models/SearchObject';
import { PriceListSearchCriteria } from '../shared/PriceListSearchCriteria';
import { DataTableColumn } from 'src/app/shared/data-table/data-table.component';
import { Company } from 'src/app/shared/models/Company';
import { Router } from '@angular/router';
import { Client } from 'src/app/shared/models/Client';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-all-price-lists',
  templateUrl: './all-price-lists.component.html',
  styleUrls: ['./all-price-lists.component.css']
})

export class AllPriceListsComponent implements OnInit {

  public priceLists: PriceList[] = [];
  public selectedPriceLists = Array();
  public newPriceListToUpload: File = null;
  public newPriceListName = '';
  public uploadNewPriceListName = '';
  public uploadNewPriceListLabel = '';
  public companySelectSearchOptions: Option[] = [];
  public showModalLoading = true;
  public disableModalButtons = true;
  public selectedModalCompany: any;
  public duplicationMethod = '1';
  public hideCompanySearch = true;
  public priceListUploadError = undefined;

  public tBodyTrAdditionalClass = 'alternateBgColor';

  public choosenClientObject = null;

  private _choosenPriceListId: number;
  private _choosenCompany: Company;


  private _pricelistUploadErrorOne = 'Category name is empty at row ';
  private _pricelistUploadErrorTwo = 'Product name is empty at row ';
  private _pricelistUploadErrorThree = 'Product price is empty at row ';
  private _pricelistUploadErrorFour = 'Product price is not a number at row ';
  private _pricelistUploadErrorFive = 'Option is empty at row ';
  private _pricelistUploadErrorSix = 'Option price is empty at row ';

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<PriceList>> = null;
  filters: PriceListSearchCriteria = {};
  columns: DataTableColumn[];

  @ViewChild('selectColumn', { static: true })
  selectColumn: TemplateRef<any>;

  @ViewChild('nameColumn', { static: true })
  nameColumn: TemplateRef<any>;

  @ViewChild('createAtColumn', { static: true })
  createAtColumn: TemplateRef<any>;

  @ViewChild('statusColumn', { static: true })
  statusColumn: TemplateRef<any>;

  @ViewChild('associatedEventColumn', { static: true })
  associatedEventColumn: TemplateRef<any>;

  @ViewChild('associatedCompanyColumn', { static: true })
  associatedCompanyColumn: TemplateRef<any>;

  @ViewChild('viewColumn', { static: true })
  viewColumn: TemplateRef<any>;

  @ViewChild('actionsColumn', { static: true })
  actionsColumn: TemplateRef<any>;

  @ViewChild('duplicatePriceListModal', { static: true })
  duplicatePriceListModal: TemplateRef<any>;

  @ViewChild('confirmDeletePriceListDialog', { static: true })
  confirmDeletePriceListDialog;

  @ViewChild('priceListInput', { static: true })
  priceListInput: ElementRef;

  public currentLanguage;

  constructor(private _router: Router, private _priceListService: PriceListService, private _toastr: ToastrService, private titleService: Title,
    private _translate: TranslateService, private _modalService: NgbModal, private _clientsService: ClientsService) {
    this.loadPage = (page: number, pageSize: number) => this._priceListService.searchPriceLists(page, pageSize, this.filters);
  }

  ngOnInit() {

    this.titleService.setTitle("Pricelists - FAdminFrontend");

    this.currentLanguage = localStorage.getItem('locale');

    this.columns = [
      {
        name: 'selectColumn',
        displayed: true,
        translationKey: '',
        template: this.selectColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'nameColumn',
        displayed: true,
        translationKey: 'Price List Name',
        template: this.nameColumn,
        sortable: true,
      },
      {
        name: 'createAtColumn',
        displayed: true,
        translationKey: 'Created At',
        template: this.createAtColumn,
        sortable: true,
      },
      {
        name: 'statusColumn',
        displayed: true,
        translationKey: 'Status',
        template: this.statusColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'associatedEventColumn',
        displayed: true,
        translationKey: 'Associated Event',
        template: this.associatedEventColumn,
        sortable: true,
      },
      {
        name: 'associatedCompanyColumn',
        displayed: true,
        translationKey: 'Associated Company',
        template: this.associatedCompanyColumn,
        sortable: true,
      },
      {
        name: 'viewColumn',
        displayed: true,
        translationKey: '',
        template: this.viewColumn,
        sortable: true,
        additionalClass: 'text-center',
        trAddidionalClass: 'text-center'
      },
      {
        name: 'actionsColumn',
        displayed: true,
        translationKey: '',
        template: this.actionsColumn,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      },
    ];
    this._clientsService.getAllCompanies().subscribe(companies => {
      companies.forEach(company => {
        this.companySelectSearchOptions.push({ value: company.id, label: company.name });
        this.showModalLoading = false;
        this.disableModalButtons = false;
      });
    });

    this.uploadNewPriceListLabel = 'Upload your new price List';
  }

  onCheckboxChecked(priceListId: number, event) {
    if (event.target.checked) {
      this.selectedPriceLists.push(priceListId);
    } else {
      const index = this.selectedPriceLists.findIndex(value => value === priceListId);
      delete this.selectedPriceLists[index];
    }
  }

  createNewPriceListClickEvent() {
    if (this.newPriceListName !== '' && this._choosenCompany !== undefined) {
      this._priceListService.insertNewPriceList(this.newPriceListName, this._choosenCompany.id).subscribe(result => {
        this.priceLists.push(result);
        console.log(result.id);
        this.newPriceListName = '';
        this._choosenCompany = undefined;
        this._router.navigate(['/price-lists/edit/', result.id]);
      }, error => {
        console.log(error);
      });
    } else {
      console.error('missing price list name or company');
      this._toastr.error('Missing price list name or company');
    }
  }

  uploadNewPriceListClickEvent() {
    if (this.uploadNewPriceListName == null) {
      // this.priceListNameError = this._translate.instant('price-list.dashboard.priceListNameError');
      console.error('Missing price list name');
      this._toastr.error('Missing price list name');
      return;
    }
    if (this.newPriceListToUpload === null) {
      console.error('Missing price list file');
      this._toastr.error('Missing price list file');
      return;
    }
    if (this._choosenCompany === undefined) {
      console.error('Missing Company');
      this._toastr.error('Missing Company');
      return;
    }
    this._priceListService.uploadNewPriceListToEvent(
      this.newPriceListToUpload, this._choosenCompany.id.toString(), this.uploadNewPriceListName).subscribe(result => {
        this._toastr.success(this._translate.instant('price-list.uploadSuccess'));
        this.priceLists.push(result);
        this.newPriceListName = '';
        this._choosenCompany = undefined;
        this.choosenClientObject = null;
        this.newPriceListToUpload = null;
        this.uploadNewPriceListName = '';
        this.uploadNewPriceListLabel = '';
        this.priceListInput.nativeElement.value = '';
        this.loadPage = (page: number, pageSize: number) => this._priceListService.searchPriceLists(page, pageSize, this.filters);
        // this.ngOnInit();
      }, error => {
        console.log(error);
        let msgError = '';
        if (error.status === 603) {
          msgError = this._pricelistUploadErrorOne + error.error.index + ' and column ' + error.error.col;
        }
        if (error.status === 604) {
          msgError = this._pricelistUploadErrorTwo + error.error.index + ' and column ' + error.error.col;
        }
        if (error.status === 605) {
          msgError = this._pricelistUploadErrorThree + error.error.index + ' and column ' + error.error.col;
        }
        if (error.status === 606) {
          msgError = this._pricelistUploadErrorFour + error.error.index + ' and column ' + error.error.col;
        }
        if (error.status === 607) {
          msgError = this._pricelistUploadErrorFive + error.error.index + ' and column ' + error.error.col;
        }
        if (error.status === 608) {
          msgError = this._pricelistUploadErrorSix + error.error.index + ' and column ' + error.error.col;
        }
        this.priceListUploadError = '<strong>' + msgError + '</strong>';
      });
  }

  onClientSelectedEvent(client: Client) {
    this.choosenClientObject = client;
    this._choosenCompany = client.company;
  }

  handlePriceListFileInput($event, files: FileList) {
    console.log(files);
    this.newPriceListToUpload = files.item(0);
    this.uploadNewPriceListName = this.newPriceListToUpload.name;
    this.uploadNewPriceListLabel = this.newPriceListToUpload.name;
    console.log($event);
  }

  changePriceListStatus(selectedPriceListId: number, newStatus: number) {

  }

  duplicatePriceListButtonClickEvent(priceListId: number, companyId: number) {
    this.showModalLoading = false;
    this.disableModalButtons = false;
    this._modalService.open(this.duplicatePriceListModal, { backdrop: 'static', keyboard: false }).result.then(() => {
      let validationError = false;
      if (this.duplicationMethod === '2') {
        if (this.selectedModalCompany) {
          companyId = this.selectedModalCompany;
        } else {
          validationError = true;
        }
      }
      if (!validationError) {
        this._priceListService.duplicatePriceList(priceListId, companyId).subscribe(result => {
          this.loadPage = (page: number, pageSize: number) => this._priceListService.searchPriceLists(page, pageSize, this.filters);
        }, error => {
          console.log(error);
        });
      }
      this._resetDuplicateModalContent();
    }, (reason) => {
      this._resetDuplicateModalContent();
    });
  }

  private _resetDuplicateModalContent() {
    this.showModalLoading = true;
    this.disableModalButtons = true;
    this.hideCompanySearch = true;
    this.duplicationMethod = '1';
    this.selectedModalCompany = undefined;
  }

  onDuplicateMethodChange() {
    if (this.duplicationMethod === '2') {
      this.hideCompanySearch = false;
    } else {
      this.hideCompanySearch = true;
    }
  }

  onDeletePriceListClickEvent(priceListId: number) {
    this._choosenPriceListId = priceListId;
    this.confirmDeletePriceListDialog.openDialog();
  }

  getDeletePriceListDecision(decision) {
    if (decision) {
      this._priceListService.deletePriceList(this._choosenPriceListId).subscribe(result => {
        this._choosenPriceListId = undefined;
        this.loadPage = (page: number, pageSize: number) => this._priceListService.searchPriceLists(page, pageSize, this.filters);
        this._toastr.success(this._translate.instant('price-list.deleteSuccess'));
      });
    } else {
      this._choosenPriceListId = undefined;
    }
  }

  exportPriceList(priceListId: number, priceListName: string) {
    this._priceListService.exportPriceList(priceListId, 'de').subscribe(result => {
      saveAs(result, 'export_' + priceListName + '.xlsx');
    });
  }

}
