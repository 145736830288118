import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PriceList } from 'src/app/shared/models/PriceList';
import { PriceListService } from '../shared/price-list.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-price-list',
  templateUrl: './manage-price-list.component.html',
  styleUrls: ['./manage-price-list.component.css']
})
export class ManagePriceListComponent implements OnInit {

  public productCategoryForm: FormGroup;
  public errorArray = new Array();
  public priceListId: number;
  public priceList: PriceList = new PriceList();
  public priceListNameError = '';
  public clientErrorArray = Array();
  private _currentCompanyId: number;
  public productCategoryOptions: Option[] = [];
  public disableAddProductBtn = false;
  public unAssignedApprovedEventsArray = new Array();
  public assignetEventId: number;

  @ViewChild(NgbTabset, { static: true })
  public tabset: NgbTabset;

  constructor(private _activatedRoute: ActivatedRoute, private _priceListService: PriceListService, private titleService: Title,
    private _formBuilder: FormBuilder, public jwtHelper: JwtHelperService,
    private _toastr: ToastrService, private _translate: TranslateService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.priceListId = params['priceListId'];
      if (this.priceListId) {
        this._priceListService.gatPriceListsById(this.priceListId.toString()).subscribe(result => {
          console.log(result);
          this._currentCompanyId = result.company.id;
          this.priceList = result;
          this.titleService.setTitle("[" + this.priceList.id + "] Edit Pricelist - FAdminFrontend");
          this._populateProductCategoryOptions();
          this.productCategoryForm = this._formBuilder.group({
            name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
          });
          if (this.priceList.event) {
            this.assignetEventId = this.priceList.event.id;
          }
          this._getUnAssignedApprovedEventsArray();
          console.log(this.priceList);
        }, error => {
          console.log(error);
          if (error.status === 600 || error.status === 601) {
            // this._navigation.priceListDashboard().go();
          }
        });
      } else {
        // this._navigation.priceListDashboard().go();
      }
    });
  }

  private _getUnAssignedApprovedEventsArray() {
    this.unAssignedApprovedEventsArray = [];
    this._priceListService.getUnAssignedApprovedEvents(this._currentCompanyId).subscribe(events => {
      events.forEach(event => {
        this.unAssignedApprovedEventsArray.push({ value: event.id.toString(), label: event.eventName });
      });
    }, error => {
      console.log(error);
    });
  }

  private _populateProductCategoryOptions() {
    this.productCategoryOptions = [];
    this.priceList.productCategories.forEach(category => {
      this.productCategoryOptions.push({ value: category.id.toString(), label: category.name });
    });
  }

  onProductCategorySubmit() {
    this.errorArray = new Array();
    if (this.productCategoryForm.valid) {
      const newProductCategory = new ProductCategory();
      newProductCategory.name = this.productCategoryForm.value.name;
      newProductCategory.priceList = this.priceList;
      newProductCategory.status = 1;
      this._priceListService.insertNewProductCategory(newProductCategory).subscribe(result => {
        this.priceList.productCategories.push(result);
        this.productCategoryForm.reset();
        this._toastr.success(this._translate.instant('price-list.manage.productCategoryAddSuccessMsg'));
      }, error => {
        console.log(error);
      });
    }
    this.clientErrorArray = ValidationUtils.getFormValidationErrors(this.productCategoryForm, 'price-list.manage');
  }

  updatePriceListName() {
    this.priceListNameError = '';
    if (this.priceList.name !== '') {
      let companyId;
      if (!this.priceList.company) {
        companyId = this.priceList.clientUser.company.id;
      } else {
        companyId = this.priceList.company.id;
      }
      this._priceListService.updatePriceList(this.priceList.id, this.priceList.name, companyId).subscribe(result => {
        this._toastr.success(this._translate.instant('price-list.manage.priceListNameUpdateSuccess'));
      }, error => {
        if (error.status === 605) {
          this.priceListNameError = 'price-list.manage.errorMsg.priceListNameExistError';
        }
      });
    } else {
      this.priceListNameError = 'price-list.manage.errorMsg.required';
    }
  }

  updatePriceListAssociatedEvent() {
    console.log(this.assignetEventId);
    this._priceListService.assignPriceListToEvent(this.priceList.id, this.assignetEventId).subscribe(result => {
      this.priceList.event = result.event;
      this._toastr.success(this._translate.instant('price-list.manage.associatedEventSuccessMsg'));
    }, error => {
      console.log(error);
    });
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === 'products-tab') {
      this._populateProductCategoryOptions();
    }
  }

  getProductEditMood(value: boolean) {
    this.disableAddProductBtn = value;
  }

  onMultipleOptionsAdded() {
    this._priceListService.gatPriceListsById(this.priceListId.toString()).subscribe(result => {
      this.priceList = result;
      this.tabset.select('products-tab');
    });
  }

}
