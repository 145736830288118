import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Client } from '../../models/Client';
import { SearchObjectResult } from '../../models/SearchObject';
import { Observable } from 'rxjs';
import { DataTableColumn } from '../../data-table/data-table.component';
import { ClientSearchCriteria } from 'src/app/clients/shared/client-search-criteria.model';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { Company } from '../../models/Company';

@Component({
  selector: 'app-client-company-modal',
  templateUrl: './client-company-modal.component.html',
  styleUrls: ['./client-company-modal.component.css'],
  providers: [NgbModalConfig, NgbModal]
})
export class ClientCompanyModalComponent implements OnInit {

  public client: Client = new Client();
  public company: Company;
  public createNewClientEnv = true;

  @Input()
  searchIcon = true;

  @Output()
  getSelectedClient: EventEmitter<Client> = new EventEmitter<Client>();

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Client>> = null;
  filters: ClientSearchCriteria = {};

  @ViewChild('idColumn', { static: true })
  idColumn: TemplateRef<any>;

  @ViewChild('firstNameColumn', { static: true })
  firstNameColumn: TemplateRef<any>;

  @ViewChild('lastNameColumn', { static: true })
  lastNameColumn: TemplateRef<any>;

  @ViewChild('emailColumn', { static: true })
  emailColumn: TemplateRef<any>;

  @ViewChild('usernameColumn', { static: true })
  usernameColumn: TemplateRef<any>;

  @ViewChild('companyColumn', { static: true })
  companyColumn: TemplateRef<any>;

  @ViewChild('addressColumn', { static: true })
  addressColumn: TemplateRef<any>;

  @ViewChild('actionsColumn', { static: true })
  actionsColumn: TemplateRef<any>;

  columns: DataTableColumn[];

  constructor(private _modalService: NgbModal, private _clientsService: ClientsService, modalConfig: NgbModalConfig) {
    modalConfig.backdrop = 'static';
    modalConfig.keyboard = false;
  }

  ngOnInit() {
  }


  open(content) {
    this._initModal();
    this.columns = [
      {
        name: 'id',
        displayed: true,
        translationKey: 'client-list.id.label',
        template: this.idColumn,
        sortable: true
      },
      {
        name: 'firstName',
        displayed: true,
        translationKey: 'client-list.firstName.label',
        template: this.firstNameColumn,
        sortable: true
      },
      {
        name: 'lastName',
        displayed: true,
        translationKey: 'client-list.lastName.label',
        template: this.lastNameColumn,
        sortable: true
      },
      {
        name: 'email',
        displayed: true,
        translationKey: 'client-list.email.label',
        template: this.emailColumn,
        sortable: true
      },
      {
        name: 'username',
        displayed: true,
        translationKey: 'client-list.username.label',
        template: this.usernameColumn,
        sortable: true
      },
      {
        name: 'company',
        displayed: true,
        translationKey: 'client-list.company.label',
        template: this.companyColumn,
        sortable: true
      },
      {
        name: 'address',
        displayed: true,
        translationKey: 'client-list.address.label',
        template: this.addressColumn,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'client-list.actions.label',
        template: this.actionsColumn,
        sortable: true
      }
    ];
    this._modalService.open(content, { size: 'lg', windowClass: 'client-company-modal' });
  }

  private _initModal() {
    this.client = new Client();
    this.company = undefined;
    this.createNewClientEnv = true;
    this.loadPage = (page: number, pageSize: number) => this._clientsService.search(page, pageSize, this.filters);
  }

  onTableRowClickEvent(item: Client) {
    console.log(item);
    this.client = Client.fromDto(item);
    this.company = Company.fromDto(this.client.company);
    this.createNewClientEnv = false;
  }

  onResetButtonClickEvent() {
    this._initModal();
  }

  // selectClientClickEvent() {
  //   this.getSelectedClient.emit(this.client);
  //   this._modalService.dismissAll();
  // }

  getSelectedCompany(recivedCompanyObject: any) {
    console.log(recivedCompanyObject);
    this.company = recivedCompanyObject;
  }

  getSelectedParentAdmin() {

  }

  updateClientClickEvent(createNew: boolean) {
    console.log(this.client);
    console.log(this.company);
    if (!this.client.username || !this.client.password || !this.client.firstEmail) {
      // this._toastr.error('Missing Client Username, Password or First Email!!!');
      console.error('Username, Password and First Email are missing for the Client');
      return;
    } else {
      if (!this.validateEmail(this.client.firstEmail)) {
        console.error('Invalid Client First Email');
        // this._toastr.error('Invalid Client First Email!!!');
        return;
      }
      if (this.company !== undefined) {
        if (this.company.firstEmail !== '') {
          if (!this.validateEmail(this.company.firstEmail)) {
            console.error('Invalid Company First Email');
            // this._toastr.error('Invalid Company First Email!!!');
            return;
          }
        }
      }
      // this.client.administrator = (this.client.parentAdminId === undefined ? true : false);
      // this.client.company.country = this.countries.filter(country => country.id === this.selectedCountry)[0];
      if (createNew) {
        this._clientsService.addClient(this.client, this.company).subscribe(result => {
          // this._toastr.success('Client added!!');
          this._initModal();
        }, error => {
          console.log(error);
        });
      } else {
        this._clientsService.updateSpecificClientCompany(this.client, this.company).subscribe(result => {
          // this._toastr.success('Client added!!');
          this._initModal();
        }, error => {
          console.log(error);
        });
      }
    }
  }

  validateEmail(email) {
    // tslint:disable-next-line:max-line-length
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}
