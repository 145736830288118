import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Village } from 'src/app/shared/models/Village';
import { Province } from 'src/app/shared/models/Province';
import { VillagesService } from './shared/villages.service';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-villages',
  templateUrl: './villages.component.html',
  styleUrls: ['./villages.component.css'],
})

export class VillagesComponent implements OnInit {

  public choosenEditVillage: Village = new Village();
  private _choosenVillageId: number;
  public filteredVillages: Village[];
  private _searchTerm: string;

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.filteredVillages = this.filtererVillages(value);
  }

  @Input()
  village: Village;

  @Input()
  provincesOptions: Option[];

  @Input()
  villagesList: Village[];

  @Input()
  provincesList: Province[];

  @ViewChild('confirmDeleteVillageDialog', { static: true })
  confirmDeleteVillageDialog;

  constructor(private _villagesService: VillagesService, private _toastr: ToastrService) {
  }

  ngOnInit() {
    this._villagesService.getAllVillages().subscribe(result => {
      this.filteredVillages = result;
    });
  }

  resetVillageList(villagesListResponse: Village[]) {
    this.filteredVillages = villagesListResponse;
  }

  enableEditVillage(village: Village) {
    this.choosenEditVillage = { ...village };
  }

  showConfirmDeleteDialog(villageId: number) {
    this._choosenVillageId = villageId;
    this.confirmDeleteVillageDialog.openDialog();
  }

  getDeleteVillageDecision(decision: boolean) {
    if (decision) {
      this._villagesService.deleteVillage(this._choosenVillageId).subscribe(result => {
        const index = this.villagesList.findIndex(village => village.id === this._choosenVillageId);
        this.villagesList.splice(index, 1);
        this._toastr.success('Village successfully deleted');
      }, error => {
        console.log(error);
      });
    }
    this._choosenVillageId = undefined;
  }

  cancelVillageEditMode() {
    this.choosenEditVillage = new Village();
  }

  filtererVillages(searchString: string) {
    return this.villagesList.filter(village =>
      village.name.toLocaleLowerCase().indexOf(searchString.toLowerCase()) !== -1);
  }
}

