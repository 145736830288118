import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../shared/devices.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Option } from 'src/app/shared/select-search/select-search.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from 'src/app/shared/models/Device';
import { DeviceStatusEnum } from '../../shared/enums/DeviceStatusEnum';
import { ToastrService } from 'ngx-toastr';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { DeviceManufacturers } from 'src/app/shared/models/DeviceManufacturers';
import { ValidationUtils } from 'src/app/clients/shared/validation.utils';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-devices-update',
  templateUrl: './devices-update.component.html',
  styleUrls: ['./devices-update.component.css']
})
export class DevicesUpdateComponent implements OnInit {

  public deviceFormGroup: FormGroup;

  private _deviceId: string;
  private _method: string;
  public device: Device;
  public deviceTypeVals: DeviceType[] = [];
  public deviceManufacturersVals: DeviceManufacturers[] = [];
  public deviceManufacturer: [];
  public validationErrorsArray = [];

  public deviceStatus: Option[] = [
    { value: DeviceStatusEnum.Deleted, label: 'Deleted' },
    { value: DeviceStatusEnum.Available, label: 'Available' },
    { value: DeviceStatusEnum.Rented, label: 'Rented' },
    { value: DeviceStatusEnum.Damaged, label: 'Damaged' },
  ];

  constructor(private _toastr: ToastrService, private _route: ActivatedRoute, private _router: Router, private titleService: Title,
    private _devicesService: DevicesService, private _formBuilder: FormBuilder) {
    this.deviceFormGroup = this._formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      status: [1],
      deviceType: [, Validators.required],
      deviceManufacturers: [, Validators.required],
      notes: ['']
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._deviceId = params['deviceId'];
      if (this._deviceId !== undefined) {
        this.titleService.setTitle("[" + this._deviceId + "] Edit Device - FAdminFrontend");
      } else {
        this.titleService.setTitle("Add Device - FAdminFrontend");
      }
      this._method = params['methodType'];
      if (this._method !== 'update' && this._method !== 'insert') {
        this._router.navigate(['/hardware/devices']);
      }
      this._devicesService.getAllDeviceManufacturers().subscribe(result => {
        this.deviceManufacturersVals = result;
      });
      this._devicesService.getAllDeviceTypes().subscribe(result => {
        this.deviceTypeVals = result;
      });
    });
    if (this._method === 'update') {
      this._devicesService.getAlldDevicesById(this._deviceId).subscribe(result => {
        this.deviceFormGroup.patchValue(result);
        this.deviceFormGroup.get('deviceType').setValue(result.deviceType.id);
        this.deviceFormGroup.get('deviceManufacturers').setValue(result.deviceManufacturer.id);
      });
    }
  }

  onDeviceFormsSubmit() {
    this.validationErrorsArray = [];
    if (this.deviceFormGroup.valid) {
      this.device = Device.fromDto(this.deviceFormGroup.value);
      this.deviceFormGroup.value.deviceType = this.deviceTypeVals.filter(deviceType =>
        deviceType.id.toString() === this.deviceFormGroup.value.deviceType.toString())[0];
      this.deviceFormGroup.value.deviceManufacturers = this.deviceManufacturersVals.filter(deviceManufacturers =>
        deviceManufacturers.id.toString() === this.deviceFormGroup.value.deviceManufacturers)[0];
      this.device.notes = (this.device.notes !== '') ? this.device.notes : null;
      if (this._method === 'update') {
        console.log('this.device:', this.device);
        this._devicesService.updateDevice(this.device).subscribe(result => {
          this._toastr.success('Device updated successfully');
        });
      } else {
        if (this._method === 'insert') {
          this._devicesService.insertDevice(this.device).subscribe(result => {
            this._toastr.success('Device added successfully');
            this.deviceFormGroup.reset({ status: 1 });

          });
        }
      }
    }
    this.validationErrorsArray = ValidationUtils.getFormValidationErrors(this.deviceFormGroup, 'device');
  }
}


