import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, Query, ViewChild } from '@angular/core';
import { Client } from 'src/app/shared/models/Client';
import { NgbTabChangeEvent, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Option } from 'src/app/shared/select/select.component';
import { Country } from 'src/app/shared/models/Country';
import { ClientsService } from 'src/app/clients/shared/clients.service';
import { Company } from 'src/app/shared/models/Company';

@Component({
  selector: 'app-data-tabset',
  templateUrl: './data-tabset.component.html',
  styleUrls: ['./data-tabset.component.css']
})
export class DataTabsetComponent implements OnInit, OnChanges {

  public countryObjects: Country[] = [];
  public countrySelectOptions: Option[] = [];
  public selectedCompany = undefined;
  public selectedCompanyAction = 'no';

  @ViewChild('clientInformationsTabset', { static: true })
  private _tabSet: NgbTabset;

  @Input()
  client: Client;

  @Input()
  company: Company;

  @Input()
  createNewClientEnv: boolean;

  @Input()
  hideCompanyTab = true;

  @Output()
  private _onSelectedCompany: EventEmitter<any> = new EventEmitter<any>();
  public get onSelectedCompany(): EventEmitter<any> {
    return this._onSelectedCompany;
  }
  public set onSelectedCompany(value: EventEmitter<any>) {
    this._onSelectedCompany = value;
  }

  constructor(private _clientsService: ClientsService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
    if (changes.company.currentValue) {
      console.log(Object.keys(changes.company.currentValue).length);
      if (Object.keys(changes.company.currentValue).length > 1) {
        this.selectedCompany = Company.fromDto(changes.company.currentValue);
        this.selectedCompanyAction = 'search';
        this.hideCompanyTab = false;
      }
      if (changes.company.currentValue !== undefined) {
        this.hideCompanyTab = false;
      }
    } else {
      this.hideCompanyTab = true;
      this._tabSet.select('client-info-tab');
      this.selectedCompanyAction = 'no';
    }
  }

  onTabChange($event: NgbTabChangeEvent) {
    console.log($event);
    if ($event.nextId === 'company-info-tab') {
      if (this.countryObjects.length === 0) {
        this._clientsService.getAllCountries().subscribe(result => {
          this.countryObjects = result;
          for (const key in result) {
            if (result.hasOwnProperty(key)) {
              const element = result[key];
              this.countrySelectOptions.push({value: element.id, label: element.name});
            }
          }
          if (!this.company.country) {
            this.company.country = this.countryObjects.filter(country => country.id === 1)[0];
          }
          console.log(this.company);
        }, error => {
          console.log(error);
        });
      }
    }
  }

  getEnableCompanySectionValue(value: string) {
    console.log('getEnableCompanySectionValue');
    this.selectedCompanyAction = value;
    switch (value) {
      case 'no':
        console.log('no');
        this.hideCompanyTab = true;
        this.company = undefined;
        this._onSelectedCompany.emit(undefined);
        break;
      case 'search':
        console.log('search');
        if (this.company === undefined || Object.keys(this.company).length === 1) {
          console.log('undefined');
          this.hideCompanyTab = true;
        } else {
          this.hideCompanyTab = false;
        }
        break;
      default:
        console.log('new');
        this.hideCompanyTab = false;
        this.company = new Company();
        this._onSelectedCompany.emit(this.company);
        break;
    }
  }

  getSelectedCompany(selectedCompany: Company) {
    console.log('--------- getSelectedCompany ---------');
    this.hideCompanyTab = false;
    this._onSelectedCompany.emit(selectedCompany);
  }
}
